import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { IconButton, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RedoIcon from '@material-ui/icons/Redo';
import { ValueGroup, ValueSelection } from '../App';
import { getValueSelection } from '../services/groups-helper';
import VFContainer from './VFContainer';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	root: {
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: "0.875rem",
	},
	iconRoot: {

	},
	buttonActive: {
		color: theme.palette.secondary.main,
	},
	sectionTitle: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(4),
	},
}));

export interface ListViewProps {
	// valueStates: ValueStates,
	seenValues: string[],
	unseenValuesBase: string[],
	unseenValuesExtended: string[],
	valueGroups: ValueGroup[],
	onValueUpdate(value: string, state: ValueSelection): void,
}

function ListViewItem(props: {value: string, selection: ValueSelection, onValueUpdate(value: string, state: ValueSelection): void}) {
	const classes = useStyles();
	const [selection, setSelection] = useState(props.selection);
	// const state = props.valueStates[value];

	const handleUpClick = () => {
		const newSelection = selection === ValueSelection.selected ? ValueSelection.initial : ValueSelection.selected;
		setSelection(newSelection);
		props.onValueUpdate(props.value, newSelection); 
	}
	const handleDownClick = () => {
		const newSelection = selection === ValueSelection.rejected ? ValueSelection.initial : ValueSelection.rejected;
		setSelection(newSelection);
		props.onValueUpdate(props.value, newSelection); 
	}

	return (
	  <ListItem key={props.value} button>
		<ListItemAvatar>
			<Avatar style={{color: "white"}} className={classes.avatar}>{props.value.substr(0,1)}</Avatar>
		</ListItemAvatar>
		<ListItemText primary={props.value} />
		<ListItemSecondaryAction>
		  <IconButton onClick={handleDownClick}><RedoIcon className={selection === ValueSelection.rejected ? classes.buttonActive : undefined} classes={{root: classes.iconRoot}}/></IconButton>
		  <IconButton onClick={handleUpClick}><FavoriteIcon className={selection === ValueSelection.selected ? classes.buttonActive : undefined} classes={{root: classes.iconRoot}}/></IconButton>
		</ListItemSecondaryAction>
	  </ListItem>
	);
}

export default function ListView(props: ListViewProps) {

	const classes = useStyles();

	return (
		<VFContainer>
			{props.seenValues.length > 0 && <>
				<Typography variant="h4" gutterBottom className={classes.sectionTitle}><LText en="Rated Values" de="Gesichtete Werte"/></Typography>
				<List dense className={classes.root}>
				{[...props.seenValues].sort().map((value) => <ListViewItem key={value} value={value} selection={getValueSelection(props.valueGroups, value)} onValueUpdate={props.onValueUpdate}/>)}
				</List>
			</>}
			{props.unseenValuesBase.length > 0 && <>
				<Typography variant="h4" gutterBottom className={classes.sectionTitle}><LText en="Remaining Values" de="Verbleibende Werte"/></Typography>
				<List dense className={classes.root}>
				{[...props.unseenValuesBase].sort().map((value) => <ListViewItem key={value} value={value} selection={getValueSelection(props.valueGroups, value)} onValueUpdate={props.onValueUpdate}/>)}
				</List>
			</>}
			{props.unseenValuesExtended.length > 0 && <>
				<Typography variant="h4" gutterBottom className={classes.sectionTitle}><LText en="Remaining Values (Extended)" de="Verbleibende Werte (Erweiterte Auswahl)"/></Typography>
				<List dense className={classes.root}>
				{[...props.unseenValuesExtended].sort().map((value) => <ListViewItem key={value} value={value} selection={getValueSelection(props.valueGroups, value)} onValueUpdate={props.onValueUpdate}/>)}
				</List>
			</>}
		</VFContainer>
	);
}
