import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Slide, useMediaQuery } from '@material-ui/core';
import ThoughtProcessIllustration from './ThoughtProcessIllustrationPathly';
import LText from '../tools/LText';

export interface Step1EnoughProps {
	onContinue(): void,
	onGroup(): void,
	numPrimary: number,
	numFurther: number,
}

export default function Step1Enough(props: Step1EnoughProps) {

	const highEnough = useMediaQuery('(min-height:650px)');

	return (
		<>
			<Box marginLeft="auto" marginRight="auto" width="100%" maxWidth="300px" position="absolute" top="50%" left="50%" style={{transform:"translate(-50%, -50%)"}} textAlign="center" paddingBottom={8}>
			{/* <img src={img} style={{maxWidth: "100%", marginBottom:"32px"}}/>  */}
				{highEnough &&
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={700}>
					 <div 
					//  style={{maxHeight: 75, maxWidth: 150}}
					 ><ThoughtProcessIllustration/></div>
				</Slide>
				}
				<Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
					<Box>
						<Typography variant="h4" gutterBottom><LText en="Quick or thorough?" de="Schnell / Gründlich"/></Typography>
						<Typography variant="body1" gutterBottom style={{marginBottom: "16px"}}><LText en="You have browsed the {numPrimary} most common values. You can now proceed to create groups from what you've marked so far." de="Du hast die wichtigsten Werte gesichtet. Du kannst nun daraus Gruppen erstellen." values={{numPrimary: props.numPrimary}}/></Typography>
						<Button onClick={props.onGroup} variant="contained" color="secondary" style={{color: "white", paddingLeft: "50px", paddingRight: "50px"}}><LText en="Group your values" de="Werte gruppieren"/></Button>
						<Typography variant="body1" gutterBottom style={{marginTop: 24}}><LText en="Want to be thorough? Continue with the full database of {numFurther} additional values." de="Willst Du besonders gründlich sein? Du kannst noch {numFurther} weitere Werte sichten." values={{numFurther: props.numFurther}}/></Typography>
					</Box>
				</Slide>
				<Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
					<Box>						
						<Button onClick={props.onContinue} variant="contained" color="primary" style={{color: "white", paddingLeft: "50px", paddingRight: "50px", marginTop: 16}}><LText en="Browse more values" de="Mehr Werte sichten"/></Button>
						<Typography variant="body2" gutterBottom style={{marginTop: 32}}><LText en="You can always switch between browsing and grouping using the main menu." de="Du kannst jederzeit im Menü zwischen Sammeln und Gruppieren wechseln."/></Typography>
					</Box>
				</Slide>
			</Box>
		</>
	);
}
