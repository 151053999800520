import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress } from '@material-ui/core';
import TocIcon from '@material-ui/icons/Toc';
import VFContainer from './VFContainer';

const useStyles = makeStyles(theme => ({
	root: {
		position: "fixed",
		// bottom: theme.spacing(2),
		bottom: 0,
		// width: "calc(100% - 50px)",
		width: "100%",
		boxSizing: "border-box",
		padding: theme.spacing(2),
		pointerEvents: "none",
		paddingBottom: "calc(env(safe-area-inset-bottom) + 16px)",
	},
}));

export interface BrowserFooterProps {
	max: number,
	completed: number,
	onListClick(): void,
}

export default function BrowserFooter(props: BrowserFooterProps) {

	const classes = useStyles();


	return (
		<Box className={classes.root}>
			<VFContainer>
				<Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
					<Box>
						<IconButton edge="start" onClick={props.onListClick} style={{pointerEvents: "all"}}><TocIcon/></IconButton>
					</Box>
					<Box>
						<Typography>{props.completed} / {props.max}</Typography> 
					</Box>
				</Box>
				{/* <LinearProgressWithLabel value={(371/509)*100} label="371/509"/> */}
				<LinearProgress variant="determinate" value={(props.completed/props.max)*100}/>
			</VFContainer>
		</Box>
	);
}
