import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
	vfcontainer: {
		maxWidth: 600,
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

interface VFContainerProps {
	children: React.ReactNode,
}
export default function VFContainer(props: VFContainerProps) {
	const classes = useStyles();

	return (
		<div className={classes.vfcontainer}>{props.children}</div>
	);
}
