import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RedoIcon from '@material-ui/icons/Redo';
import { Box, Button, ButtonBaseActions, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { hyphenateSync as hyphenateSyncEN} from "hyphen/en-us";
import { hyphenateSync as hyphenateSyncDE } from "hyphen/de-1996";
import LangContext from '../contexts/LangContext';
import DialogNotched from './DialogNotched';
import LText from '../tools/LText';


const useStyles = makeStyles(theme => ({
	cardRoot: {
		overflow: "inherit",
		// height: "45vh"
	},
	card: {
		// width: "100%",
		width: "90vw",
		maxWidth: 400,
	},
	title: {
		// fontSize: 56,
		hyphens: "auto",
		// minHeight: 300,
		textAlign: "center",
		marginTop: theme.spacing(4),
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		paddingBottom: theme.spacing(2),
	},
	iconRoot: {
		width: "2em",
		height: "2em",
	}
}));

export interface ValueCardProps {
	value: string,
	description: string | null,
	// highlight?: (callback: (direction: "left"|"right"|null) => void) => void,
	highlight?: (callback: React.MutableRefObject<(direction: "left" | "right" | null) => void>) => void,
	// swipingDirectionRef?: React.MutableRefObject< -1 | 0 | 1 >,
	swipingDirection?: -1 | 0 | 1,
	onLeftClick?(): void,
	onRightClick?(): void,
}

export default function ValueCard(props: ValueCardProps) {

	const classes = useStyles();
	const leftButtonActions = useRef<ButtonBaseActions|null>(null);
	const rightButtonActions = useRef<ButtonBaseActions|null>(null);
	const [showExplanation, setShowExplanation] = useState(false)
	const lang = useContext(LangContext);
	const { highlight } = props;

	const hyphenateSync = lang.lang === "de" ? hyphenateSyncDE : hyphenateSyncEN;

	const handleHighlight = useRef((direction: "left"|"right"|null) => {
		switch (direction) {
			case "left":
				leftButtonActions.current?.focusVisible();
				break;
			case "right":
				rightButtonActions.current?.focusVisible();
				break;
		}
	});

	useEffect(() => {
		if (highlight)
			highlight(handleHighlight);
	}, [highlight]);

	useEffect(() => {
		const direction = props.swipingDirection;
		// logger.debug("Swiping direction", props.swipingDirection);
		if (direction === -1)
			leftButtonActions.current?.focusVisible();
		else if (direction === 1)
			rightButtonActions.current?.focusVisible();
	}, [props.swipingDirection]);

	// logger.debug("Swiping direction", props.swipingDirection);

	// const description = "Konstruktivität ist ein Begriff, der sich auf eine positive, produktive und kreative Art und Weise der Kommunikation und Interaktion bezieht.\nEs bezieht sich auf die Fähigkeit, Ideen, Lösungen und Vorschläge zu erarbeiten, die zu einem positiven Ergebnis führen.\n\nEs beinhaltet auch die Fähigkeit, Probleme zu identifizieren und zu lösen, indem man kreativ denkt und konstruktive Lösungen anbietet. Konstruktivität ist ein wichtiger Bestandteil jeder erfolgreichen Zusammenarbeit, da sie es ermöglicht, dass alle Beteiligten ihre Ideen und Meinungen einbringen und ein positives Ergebnis erzielt wird.";
	// const descriptionShort = description.slice(0, 200) + "…";
	return (
		<>
		<Card classes={{root: classes.cardRoot}} className={classes.card}>
			<CardContent>
				{/* <Typography color="textPrimary" className={classes.title}>{props.value}</Typography> */}
				<Box style={{minHeight: 300}}>
				<Typography color="textPrimary" variant="h3" className={classes.title}>{hyphenateSync(props.value)}</Typography>
				{props.description && <Box textAlign="center"><Button variant="text" onClick={() => setShowExplanation(true)}>&raquo; <LText en="Description" de="Erklärung"/></Button></Box>}
				
				{/* <Box style={{marginTop: 24,  paddingLeft: 16, paddingRight: 16}}>
					<Typography color="textPrimary" variant="body1">{descriptionShort}&nbsp;<strong>MORE&nbsp;&raquo;</strong></Typography>
				</Box> */}
				</Box>
			</CardContent>
			<CardActions className={classes.actions}>
				<IconButton action={actions => leftButtonActions.current = actions} onClick={props.onLeftClick}><RedoIcon classes={{root: classes.iconRoot}}/></IconButton>
				<IconButton action={actions => rightButtonActions.current = actions} onClick={props.onRightClick}><FavoriteIcon classes={{root: classes.iconRoot}}/></IconButton>
				{/* <Button size="large" startIcon={<ThumbUpAltIcon/>}>Important</Button>
				<Button size="large" endIcon={<ThumbDownIcon/>}>Discard</Button> */}
			</CardActions>
		</Card>
		<DialogNotched
				open={showExplanation}
				onClose={() => setShowExplanation(false)}
				aria-labelledby="responsive-dialog-title"
			>
				 <DialogTitle>{hyphenateSync(props.value)}</DialogTitle>
				<DialogContent>
					{/* <Typography color="textPrimary" variant="h5" gutterBottom>{hyphenateSync(props.value)}</Typography> */}
					<Typography color="textPrimary" variant="body1" style={{whiteSpace: "pre-line"}}>{props.description}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowExplanation(false)} color="primary">
						{/* <FormattedMessage id="ButtonDialog.close" defaultMessage="Close"/> */}
						Close
					</Button>
				</DialogActions>
			</DialogNotched>
		</>
	);
}
