import React, { useContext, useRef } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import logger from '../services/logger';
import SwipableStack from './SwipableStack';
import ValueCard from './ValueCard';
import { SwipingDirection } from './Swipable';
// import FocalityCard from './FocalityCard';
import shuffle from 'lodash.shuffle';
import { logEvent } from '../services/analytics';
import valueDescriptionsDe from '../assets/valueDescriptions.de.json';
import valueDescriptionsEn from '../assets/valueDescriptions.en.json';
import LangContext from '../contexts/LangContext';

// const useStyles = makeStyles(theme => ({
// }));

export interface SwipableValuesProps {
	values: string[],
	onSwipe(value: string, direction: SwipingDirection): void,
}

interface SwipeItem {
	type: "value" | "focality",
	key: string,
	value?: string,
	element: React.ReactElement,
}

const getDescription = (value: string, lang: string) => {
	//@ts-ignore
	const desc = lang == "de" ? valueDescriptionsDe[value] : valueDescriptionsEn[value];
	if (typeof desc !== "string")
		return null;
	else
		return desc;
}

const loadItems = (values: string[], lang: string) => {
	const items = shuffle(values).map<SwipeItem>(value => ({
		type: "value",
		value: value,
		key: value,
		element: <ValueCard value={value} description={getDescription(value, lang)}/>
	}));
	// if (items.length > 20) {
	// 	items.splice(20,0, ({
	// 		type: "focality",
	// 		key: "focality",
	// 		element: <FocalityCard/>
	// 	}));
	// }
	return items;
}

export default function SwipableValues(props: SwipableValuesProps) {

	const lang = useContext(LangContext);

	const items = useRef(loadItems(props.values, lang.lang));

	const onSwipe = (index: number, direction: SwipingDirection) => {
		const item = items.current[index];
		logger.info("Swipe", index, item, direction);
		logEvent("swipe", {
			direction: direction,
		});
		if (item.type === "value" && item.value)
			props.onSwipe(item.value, direction);
	}

	logger.debug("SwipableValues, items", items.current);

	return (
		<SwipableStack
			items={items.current.map((item, idx) => ({
				element: item.element,
				key: item.key,
			}))} 
			onSwipe={onSwipe}
		/>
	);
}
