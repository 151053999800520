import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import background from '../assets/signup-bg.1920.jpg';


const useStyles = makeStyles(theme => ({
	container: {
		backgroundImage: `url(${background})`,
		backgroundSize: "cover",
		color: "white",
		
	},
	list: {
		paddingLeft: 0,
		listStyle: "none",
		"& li::before": {
			content: "\"✔\"",
			marginRight: theme.spacing(2),
		},
	},
	filter: {
		// padding: "50px "+theme.spacing(2)+"px",
		padding: theme.spacing(2),
		background: "radial-gradient(ellipse at center, #1e5799 0%,rgba(54, 151, 142, 0.1) 0%,#0C526C 100%,#3d7eaa 100%,#182848 100%,#6e48aa 100%,#6e48aa 100%)"
	}
}));

export interface FocalityBannerProps {
	onDismiss(): void,
}

export default function FocalityBanner(props: FocalityBannerProps) {

	const classes = useStyles();


	return (
		<Box className={classes.container}>
			<Box className={classes.filter}>
				<Typography variant="h5">Achieve Your Goals</Typography>
				{/* <List>
					<ListItem>
						<ListItemIcon>
							<CheckIcon />
						</ListItemIcon>
						<ListItemText primary="Focality: Deep planning, self-reflection and data-driven insights." />
					</ListItem>
					</List> */}
				<Typography variant="body1" component="span">Focality: Deep planning, self-reflection and data-driven insights.</Typography>
				<ul className={classes.list}>
					<li>
					<Typography variant="body1" component="span">Keep focused on your goals.</Typography>
					</li>
					<li>
					<Typography variant="body1" component="span">Be proactive.</Typography>
					</li>
					<li>
					<Typography variant="body1" component="span">Maximize your potential.</Typography>
					</li>
				</ul>
				<Button 
					href="https://www.focalityapp.com/"
					target="_blank"
					variant="contained"
					x-tracking="focality-banner-cta"
					//  color="primary"
					//  color="secondary"
					  style={{backgroundColor: "white", marginRight: 8}}
					  >Learn more</Button>
				<Button onClick={props.onDismiss}>Maybe later</Button>
			</Box>
		</Box>
	);
}
