import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import moodImage from '../assets/20160704-2004--DSC_2771.landscape.jpg';
import pathlyImage from '../assets/pathly-background.jpg';
import { Button, Link } from '@material-ui/core';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${pathlyImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	onDone(): void,
}

export default function OnboardingPrinciple(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}>Values Finder</Typography>
						<Typography variant="h5" color="inherit" className={classes.heading}><LText en="Define your values with Pathly" de="Pathly Values Assessment"/></Typography>
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description} paddingBottom={100}>
					<Typography variant="body1" color="inherit" paragraph><LText en="Our values are the beliefs that are most important to us, that help us find our way through struggle, lead us to fulfillment, and fill us with a feeling of purpose." de=""/></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText en="Get clear on your core values in 2 easy steps." de="Finde Deine Grundwerte in 2 einfachen Schritten"/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Step 1:" de="Schritt 1: Sammeln"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText en="Browse through the list of values. Select every possible value that you identify with." de="Finde jeden möglichen Wert, mit dem Du Dich identifizierst. Stöbere durch eine Liste mit mehreren Hundert Werten und markiere alle Ansprechenden."/></Typography>
					<Typography variant="body1" color="inherit"><strong><LText en="Step 2:" de="Schritt 2: Verdichten"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText en="Create groups of your chosen values. Prioritize which matter most to you. Pick a title for each group that feels true to you." de="Bilde Gruppen aus den gewählten Werten. Finde einen aussagekräftigen Titel für jede Gruppe."/></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText en="Your selections are <strong>auto-saved</strong> locally. You can leave &amp; continue at any time." de="Alles wird <strong>automatisch lokal gespeichert</strong>. Du kannst jederzeit pausieren und später weitermachen."/></Typography>
					<Button onClick={props.onDone} variant="contained" color="secondary" style={{width: "100%", color: "white", marginTop: 16}}><LText en="Get started" de="Los geht's"/></Button>
				</Box>
				{/* <Box className={classes.description} paddingBottom={100}>
					<Typography variant="body1" color="inherit"><strong><LText en="Are you a coach?" de="Bist Du ein Coach?"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><LText en="Customize Values Finder and offer it to your clients for free! <link-en>» Learn more</link-en>" de="Individualisiere Values Finder und biete es Deinen Coachees kostenlos an! <link-de>» Mehr erfahren</link-de>" values={{
						"link-en": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focalityapp.com/en/values-finder-coaching/">{m}</Link>,
						"link-de": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focalityapp.com/de/values-finder-coaching-tool/">{m}</Link>,
					}}/></Typography>
				</Box> */}
			</Container>
		</React.Fragment>
	);
}
