import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Box, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VFContainer from './VFContainer';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.background.default,
		paddingTop: "env(safe-area-inset-top)",
		top: 0,
	},
}));

export interface HeaderProps {
	onMenuClick(): void,
	onHelpClick(): void,
	centerElement?: React.ReactNode,
}

export default function HeaderPlain(props: HeaderProps) {

	const classes = useStyles();

	return (
		<>
			<Box className={classes.container} width="100%"  position="fixed" zIndex={1}>
				<VFContainer>
					<Box display="flex" alignItems="center" justifyContent="space-between"  paddingLeft={2} paddingRight={2}>
						<Box>
							<IconButton edge="start" color="inherit" aria-label="menu" onClick={props.onMenuClick}>
								<MenuIcon />
							</IconButton>
						</Box>
						{props.centerElement && 
							<Box>
								{props.centerElement}
							</Box>
						}
						<Box>
							<IconButton edge="end" color="inherit" aria-label="menu" onClick={props.onHelpClick}>
								<HelpOutlineIcon />
							</IconButton>
						</Box>
					</Box>
				</VFContainer>
			</Box>
			<Box paddingTop={6}></Box>
		</>
	);
}
