import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import logger from '../services/logger';
import GroupCard from './GroupCard';
import GroupCardPlaceholder from './GroupCardPlaceholder';
import UngroupedCards from './UngroupedCards';
import FocalityBanner from './FocalityBanner';
import { Button, Divider, Link, Slide, Typography } from '@material-ui/core';
import { logEvent } from '../services/analytics';
import { ValueGroup, ValueGroupIDs } from '../App';


const useStyles = makeStyles(theme => ({

	footer: {
		color: theme.palette.grey[600],
	},
}));



export default function ValueGrouperFooterPathly() {

	const classes = useStyles();


	return (
		<>
			<Box textAlign="center" paddingTop={8} paddingBottom={1} className={classes.footer}>
				<Typography variant="h6" align="center">Pathly</Typography>
				<Typography variant="body1" align="center">Performance coaching for leaders and teams</Typography>
				{/* <Button variant="text" href="https://www.instagram.com/findpathly/">@findpathly</Button> */}
				{/* <Link align="center" href="https://www.instagram.com/findpathly/">@findpathly</Link> */}
				<Typography variant="caption"><Link className={classes.footer} align="center" href="https://www.instagram.com/findpathly/">@findpathly</Link></Typography>
			</Box>
		</>
	);
}
