import { ValueGroup, ValueGroupIDs, ValueSelection } from "../App";

export function getGroupByValue(allGroups: ValueGroup[], value: string) {
	return allGroups.find(g => g.coreValue === value || g.values.indexOf(value) !== -1);
}

export function getValueSelection(allGroups: ValueGroup[], value: string) {
	const g = getGroupByValue(allGroups, value);
	if (!g)
		return ValueSelection.initial;
	if (g.id === ValueGroupIDs.rejected)
		return ValueSelection.rejected;
	return ValueSelection.selected;
}