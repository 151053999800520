import React, { useRef, useState } from 'react';
import logger from '../services/logger';
import Swipable, { SwipingDirection } from './Swipable';
import './SwipableStack.css';

export interface SwipableStackProps {
	// items: React.ReactNode[],
	// items: React.ReactElement[],
	items: {key: string, element: React.ReactElement}[],
	onSwipe?(index: number, direction: SwipingDirection): void,
}

export default function SwipableStack(props: SwipableStackProps) {
	// const [idx, setIdx] = useState(0);
	const idx = useRef(0);
	const [cards, setCards] = useState(props.items.length > 0 ? [props.items[0]] : []);
	const directionRef = useRef< -1 | 0 | 1 >(0);
	const itemsRef = useRef(props.items);
	// const itemsRef = {current: props.items};

	// useEffect(() => {
	// 	logger.debug("Resetting SwipableStack");
	// 	// setCards(props.items.length > 0 ? [props.items[0]] : []);
	// 	itemsRef.current = props.items;
	// 	idx.current = 0;
	// 	const newCards = [...cards];
	// 	if (idx.current < itemsRef.current.length) {
	// 		newCards.push(itemsRef.current[idx.current]);
	// 		setCards(newCards);
	// 		logger.debug("Gone", idx.current, newCards);
	// 	} else {
	// 		logger.debug("Last card.");
	// 	}
	// }, [props.items]);

	const handleGone = (direction: SwipingDirection, index: number) => {
		const newCards = [...cards];
		idx.current = idx.current + 1;
		if (idx.current < itemsRef.current.length) {
			newCards.push(itemsRef.current[idx.current]);
			setCards(newCards);
			logger.debug("Gone", idx.current, newCards);
		} else {
			logger.debug("Last card.");
		}
		if (props.onSwipe)
			props.onSwipe(index, direction);
	};

	return (
		<div className="CardSwiper">
			{cards.map((item, i) => (
				<Swipable key={item.key} onGoing={(direction) => {handleGone(direction, i)}} directionRef={directionRef} item={item.element}>{item.element}</Swipable>
			))}
		</div>
	);
}
