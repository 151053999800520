import raw from "raw.macro";

function processValues(valuesRaw: string) {
	return valuesRaw.replace(/\r/gm, "").split("\n").filter(value => value.length > 0);
}

export default function loadValuesEN() {
	const valuesRaw = raw('../assets/personalValuesEN.txt');
	return processValues(valuesRaw);
}

export function loadValuesDE() {
	const valuesRaw = raw('../assets/personalValuesDE.txt');
	return processValues(valuesRaw);
}

export function loadPrimaryValuesEN() {
	const valuesRaw = raw('../assets/personalValuesEN.primary.txt');
	return processValues(valuesRaw);
}

export function loadPrimaryValuesDE() {
	const valuesRaw = raw('../assets/personalValuesDE.primary.txt');
	return processValues(valuesRaw);
}
