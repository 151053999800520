import React, { createRef, useEffect, useState } from 'react';
import logger from '../services/logger';
import { ValueGroup } from '../App';
import ValueGrouperStatic from './ValueGrouperStatic';
import html2canvas from 'html2canvas';
import { ExportType } from './GroupingViewController';
import DialogNotched from './DialogNotched';
import { Button, DialogActions, DialogContent, Link, Typography } from '@material-ui/core';
import { Plugins } from '@capacitor/core';
import LText, { useLString } from '../tools/LText';

export interface GroupExporterProps {
	groups: ValueGroup[],
	ungroupedValues: string[],
	onDone(): void,
	type: ExportType,
}

export default function GroupExporter(props: GroupExporterProps) {
	const ref = createRef<HTMLDivElement>();
	const [downloadURL, setDownloadURL] = useState<string|null>(null);
	const [platform, setPlatform] = useState<"ios" | "android" | "electron" | "web" | null>(null);
	const lstr = useLString();

	useEffect(() => {
		Plugins.Device.getInfo().then(info => {
			setPlatform(info.platform);
		});
	}, []);

	useEffect(() => {
		window.scroll(0,0);
		if (ref.current) {
			
			html2canvas(ref.current, {
				windowWidth: ref.current.scrollWidth,
				windowHeight: ref.current.scrollHeight,
				scale: 3,
				// onclone: (document) => {
				// 	logger.debug("Cloned document", document);
				// 	const container = document.getElementById("group-export");
				// 	if (container)
				// 		container.style.display = "block";
				// 	logger.debug("Container", container);
				// 	logger.debug("Cloned document after modification", document);
				// },
			}).then(function(canvas) {
				// document.body.appendChild(canvas);
				logger.debug("Cancas size (width, height)", canvas.width, canvas.height);
				// if (canvas.width > 3*600) {


				// }

				const dataURL = canvas.toDataURL();
				logger.debug("Screenshot: ", dataURL.substr(0,100));

				if (props.type === ExportType.download) {
					
					setDownloadURL(dataURL);
					/*
					// Download data

					const a = document.createElement("a");
					a.style.display = "none";
					document.body.appendChild(a);
				
					// Set the HREF to a Blob representation of the data to be downloaded
					// a.href = window.URL.createObjectURL(
					//   new Blob([data], { type })
					// );
					a.href = dataURL;
				
					// Use download attribute to set set desired file name
					a.setAttribute("download", "core-values.png");
				
					// Trigger the download by simulating click
					a.click();
				
					// Cleanup
					// window.URL.revokeObjectURL(a.href);
					document.body.removeChild(a);*/
				} else {
					canvas.toBlob((blob) => {
						if (blob) {
							var file = new File([blob], "picture.jpg", {type: 'image/jpeg'});
							//@ts-ignore
							navigator.share({
								text: lstr('I discovered my core values with Values Finder', 'Ich habe meine Werte mit Values Finder entdeckt'),
								// @ts-ignore
								files: [file], 
								title: lstr('My Core Values', 'Meine Werte'),
								url: 'https://www.focalityapp.com/en/personal-core-values-assessment/'
							  });
							// Plugins.Share.share({
							// 	title: 'I discovered my core values',
							// 	files: [file],
							// 	text: 'Really awesome thing you need to see right meow',
							// 	url: "https://www.focalityapp.com/en/personal-core-values-assessment/",
							// 	dialogTitle: 'Share with buddies'
							// });
						} else {
							logger.error("Failed to retrieve blob");
						}
					})
				}
				// props.onDone();
			});
		}
	}, [ref, props, lstr]);

	const executeSave = () => {
		logger.info("Sharing image...");
		if (downloadURL) {
			const base64Data = downloadURL.replace(/^data:image\/(png|jpg);base64,/, "");
			logger.debug("Image data:", downloadURL.substr(0, 50), base64Data.substr(0, 50));
			Plugins.FileSharer.share({
				filename: "my-core-values.png",
				base64Data: base64Data,
				contentType: "application/png",
			}).then(() => {
				logger.info("File sharing executed");
			}).catch((error:any) => {
				logger.error("File sharing failed", error.message);
			});
		} else {
			logger.error("Tried to share image although downloadURL is null");
		}
	}

	return (
		<>
			{downloadURL === null && 
				<div ref={ref}>
					<ValueGrouperStatic
							groups={props.groups}
							ungroupedValues={props.ungroupedValues}
							isPreview={false}
					/>
				</div>
			}
			{downloadURL !== null && 
				<DialogNotched
					open={true}
					onClose={() => props.onDone()}
					>
					<DialogContent>
						{platform === "ios" ?
							<>
								<Typography variant="h4" gutterBottom><LText en="Download / Share" de="Herunterladen / Teilen"/></Typography>
								<Typography variant="body1" paragraph>
									<LText en="Below is a PNG (image file) of your core values. Long-press it to save/share." de="Unten befindet sich ein PNG (Bild) Deiner Werte. Halte es lange gedrückt um es herunterzuladen oder zu teilen."/>
								</Typography>
								<img src={downloadURL} style={{maxWidth: "100%", boxShadow: "0px 0px 10px gray"}} data-analytics-id="core-values-image" alt={lstr("My Core Values", "Meine Werte")}/>
								{/* <Button startIcon={<GetAppIcon/>} variant="contained" color="secondary" href={downloadURL} download="my-core-values.png" style={{color: "white"}} data-analytics-id="download-execute">Download</Button> */}
							</>
							:
							<>
								<Typography variant="h4" gutterBottom><LText en="Share / Save" de="Teilen / Speichern"/></Typography>
								<Typography variant="body1" paragraph>
									<LText en="Below is a PNG (image file) of your core values. Long-press it to save/share." de="Unten befindet sich ein PNG (Bild) Deiner Werte. Halte es lange gedrückt um es herunterzuladen oder zu teilen."/>
								</Typography>
								<Link
									component="button" 
									// href={downloadURL} 
									// download="my-core-values.png"
									onClick={executeSave}
									>
										<img src={downloadURL} style={{maxWidth: "100%", boxShadow: "0px 0px 10px gray"}} data-analytics-id="core-values-image" alt={lstr("My Core Values", "Meine Werte")}/>
								</Link>
								{/* <Button startIcon={<GetAppIcon/>} variant="contained" color="secondary" href={downloadURL} download="my-core-values.png" style={{color: "white"}} data-analytics-id="download-execute">Download</Button> */}
							</>
						}
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={() => props.onDone()}>
							<LText en="Close" de="Schließen"/>
						</Button>
					</DialogActions>
				</DialogNotched>
			}
		</>
	);
}
