import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DialogNotched from './DialogNotched';
import LText from '../tools/LText';

const useStyles = makeStyles(theme => ({
	titlePlaceholder: {
		color: theme.palette.text.hint,
	},
	editButton: {
		// paddingTop: theme.spacing(1),
		marginTop: "-3px",
	}
}));

export interface GroupTitleProps {
	value: string,
	onChange(value: string): void,
}

export default function GroupTitle(props: GroupTitleProps) {
	const [editing, setEditing] = useState(false);
	const [valueEditing, setValueEditing] = useState(props.value);
	const classes = useStyles(props);

	const handleCancel = () => {
		setEditing(false);
		setValueEditing(props.value);
	}

	const handleSave = () => {
		setEditing(false);
		props.onChange(valueEditing);
	}

	return <>
		<Typography
			variant="h6"
			className={props.value === "" ? classes.titlePlaceholder : undefined}
			onClick={() => setEditing(true)}
			>
				{props.value || <LText en="Enter title" de="Titel eingeben"/>}
				<IconButton className={classes.editButton}><EditIcon fontSize="small"/></IconButton>
		</Typography>
		<DialogNotched
			open={editing} 
			onClose={handleCancel}
			>
			<DialogTitle><LText en="Edit group title" de="Gruppentitel bearbeiten"/></DialogTitle>
			<DialogContent>
				<TextField 
				// label="Title"
				variant="standard"
				value={valueEditing}
				autoFocus
				onChange={(e) => setValueEditing(e.target.value)}
				helperText={<LText en="Choose a title or phrase to represent these values." de="Wähle einen Titel oder Phrase, um diese Werte zu repräsentieren."/>}
				/>

			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={handleCancel}><LText en="Cancel" de="Abbrechen"/></Button>
				<Button variant="text" color="secondary" onClick={handleSave}><LText en="Save" de="Speichern"/></Button>
			</DialogActions>
		</DialogNotched>
	</>;
}
