import React, { useEffect, useRef, useState } from 'react';
import { ValueGroup, ValueSelection } from '../App';
import HeaderPlain from './HeaderPlain';
import DialogNotched from './DialogNotched';
import { Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import ListView from './ListView';
import LoadingView from './LoadingView';
import LText from '../tools/LText';

export interface ListViewControllerProps {
	values: string[],
	primaryValues: string[],
	valueGroups: ValueGroup[],
	// valueStates: ValueStates,
	onValueUpdate(value: string, state: ValueSelection): void,
	onMenuClick(): void,
	// onNextStep(): void,
}

export default function ListViewController(props: ListViewControllerProps) {
	const [view, setView] = useState(<LoadingView/>);
	const [showHelp, setShowHelp] = useState(false);

	const seenValues = useRef(props.values.filter(value => {
		const group = props.valueGroups.find(g => g.coreValue === value || g.values.find(v => v === value) !== undefined);
		if (group !== undefined)
			return true;
		else
			return false;
	}));
	const unseenValues = useRef(props.values.filter(value => seenValues.current.find(v => v === value) === undefined));
	const unseenValuesBase = useRef(unseenValues.current.filter(value => props.primaryValues.find(v => v === value) !== undefined));
	const unseenValuesExtended = useRef(unseenValues.current.filter(value => props.primaryValues.find(v => v === value) === undefined));

	useEffect(() => {
		const list = (<ListView seenValues={seenValues.current} unseenValuesBase={unseenValuesBase.current} unseenValuesExtended={unseenValuesExtended.current} valueGroups={props.valueGroups} onValueUpdate={props.onValueUpdate}/>);
		setView(list);
	}, [props.onValueUpdate, props.valueGroups, props.values]);

	// const values = props.values.map(value => ({
	// 	value: value,
	// 	selection: props.valueStates[value] ? props.valueStates[value].selection : ValueSelection.initial,

	// }));

	const handleCloseHelp = () => {
		setShowHelp(false);
	}

	return (
		<>
				<HeaderPlain onMenuClick={props.onMenuClick} onHelpClick={() => setShowHelp(true)}/>
				{view}
				<DialogNotched
					open={showHelp}
					onClose={handleCloseHelp}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogContent>
						<Typography variant="h4" gutterBottom><LText en="All Values" de="Alle Werte"/></Typography>
						<Typography variant="body1" paragraph><LText en="This is the list of all available values. You can update your preferences if you wish." de="Dies ist die Liste aller verfügbaren Werte. Hier kannst Du Deine Bewertungen bei Bedarf aktualisieren."/></Typography>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleCloseHelp}>
							<LText en="Close" de="Schließen"/>
						</Button>
					</DialogActions>
			</DialogNotched>
		</>
	);
}
