import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import AdjustIcon from '@material-ui/icons/Adjust';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		height: "100vh",
	},
	"@keyframes spinning": {
		from: {
			transform: "rotate(0deg)",
		},
		to: {
			transform: "rotate(360deg)",
		}
	  },
	"@keyframes pulse": {
		"0%": {
			transform: "scale(0.95)",
			// boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
		},
		"70%": {
			transform: "scale(1)",
			// boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
		},
		"100%": {
			transform: "scale(0.95)",
			// boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
		},
	},
	logo: {
		// animationName: '$spinning',
		animation: "$pulse 2s infinite",
		marginBottom: theme.spacing(2),
		fontSize: "7rem",
	},
	timeout: {
		marginTop: theme.spacing(2),
		textAlign: "center",
	},
}));

export interface LoadingViewProps {
	what?: string,
	showReload?: boolean,
	onReload?(): void,
}

function LoadingView(props: LoadingViewProps) {
	const classes = useStyles();
	
	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.container}
		>
			<Grid item>
				<AdjustIcon fontSize="large" className={classes.logo}/>
			</Grid>
			<Grid item>
				<Typography variant="h4" style={{fontWeight: 300}} align="center" color="textPrimary" gutterBottom>
					{/* <FormattedMessage id="LoadingView.title" defaultMessage="Loading…"/> */}
					Loading…
				</Typography>
				{props.what && 
					<Typography variant="caption" style={{fontWeight: 100, width: "100%", display: "inline-block"}} align="center" color="textPrimary" gutterBottom>
						… {props.what} …
					</Typography>
				}
			</Grid>
			{props.showReload &&
				<Grid item className={classes.timeout}>
					<Typography variant="h6" style={{fontWeight: 300, marginBottom: 10}} align="center" color="textPrimary" gutterBottom>
						This is taking longer than usual. :(
					</Typography>
					<Button onClick={props.onReload} variant="contained" color="secondary" style={{color: "white", marginBottom: "20px"}}>Try again</Button>
					<Typography variant="body1" style={{fontWeight: 300}}>Issues? <Link href="mailto:info@focalityapp.com">Contact us</Link> so that we can help.</Typography>
				</Grid>
			}
		</Grid>
	);
}

export default LoadingView;
