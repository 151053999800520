import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Typography } from '@material-ui/core';
import ValueChip from './ValueChip';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1,2),
	},
	card: {
		// width: "100%",
		// marginBottom: theme.spacing(2),
		// padding: theme.spacing(2),
		// boxSizing: "border-box",
		// border: "1px solid "+theme.palette.primary.dark,
		backgroundColor: theme.palette.grey[200],
		boxShadow: "none",
	},
	cardContentRoot: {
		padding: "0 !important",
	},
	title: {
		// fontSize: 56,
		// hyphens: "auto",
		// minHeight: 300,
		textAlign: "center",
		marginBottom: theme.spacing(2),
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
	},
	iconRoot: {
		width: "2em",
		height: "2em",
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	titleChip: {
		marginRight: theme.spacing(1),
		// marginBottom: theme.spacing(1),
		color: "white",
	},
	titlePlaceholder: {
		borderStyle: "dashed",
		// marginBottom: theme.spacing(1),
	},
}));

export interface GroupCardProps {
	id: string,
	titleValue: string | null,
	title: string,
	values: string[],
}

export default function GroupCardStatic(props: GroupCardProps) {

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Card className={classes.card} elevation={2}>
				<CardContent className={classes.cardContentRoot}>
						{props.titleValue &&
							<Box textAlign="center" marginTop={2} marginBottom={0}>
								<ValueChip disableTransitions={true} value={props.titleValue || ""} variant={"title"}/>
							</Box>
						}
						{props.title !== "" &&
							<Box textAlign="center" paddingTop={1}>
								<Typography	variant="h6">{props.title}</Typography>
							</Box>
						}
								{/* <div
	
									style={{
										// backgroundColor:"lightyellow",
										minHeight: 40,
										padding: 16,
										paddingTop: 0,
									}}
									> */}
									<Box marginLeft={2} marginBottom={2} marginRight={2} textAlign="center">
									{props.values.map((item, index) => 

												<div
													style={{display: "inline-block", marginTop: 16}}
													// style={{display: "inline", ...provided.draggableProps.style}}
													key={item}
												>
													{/* <Chip label={item} color="primary" avatar={<Avatar>{item.substr(0,1)}</Avatar>} className={classes.chip}/> */}
													<Box marginRight={1}><ValueChip value={item || ""} variant={"default"} disableTransitions={true}/></Box>
												</div>
									)}
									</Box>
								{/* </div> */}
				</CardContent>
			</Card>
		</Box>
	);
}
