import logger from './logger';

declare var heap: {
	load(projectId: string, parameters: any): void,
	track(eventId: string, properties: any): void,
	identify(id: string): void,
	resetIdentity(): void,
};

var currentScreen: string | null = null;

export async function setScreenName(screenName: string) {
	if (screenName === currentScreen)
		return;
	logger.debug('Analytics: Setting streen name: '+ screenName);
	if (typeof heap !== "undefined") {
		heap.track('screen_view', {screenName: screenName});
	}
	analyticsEvent("screen_view", {
		screen_name: screenName,
	});
	currentScreen = screenName;
}

type EventParameters = {
	[index: string]: string | number,
}
export async function logEvent(event: string, parameters?: EventParameters) {
	logger.debug('Analytics: Logging event (event, parameters, platform)', event, parameters);
	if (typeof heap !== "undefined" && event !== "screen_view") {
		heap.track(event, parameters);
	}
	analyticsEvent(event, parameters);
}

function analyticsEvent(name: string, parameters: any) {
	//@ts-ignore
	if (typeof gtag !== undefined) gtag("event", name, parameters);
}

try {
	// @ts-ignore
	window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])}; // eslint-disable-line
	if (typeof heap !== "undefined" && typeof process.env.REACT_APP_HEAP_ID !== "undefined") {
		const heapId = process.env.REACT_APP_HEAP_ID;
		const heapOptions = {
			disableTextCapture: true,
			forceSSL: true,
		}
		logger.debug('Initializing heap', heapId, JSON.stringify(heapOptions));
		heap.load(heapId, heapOptions);
	} else {
		logger.error('Could not load heap: Heap object or settings missing', heap, process.env.REACT_APP_HEAP_ID);
	}
} catch (e) {
	logger.error('Error while initializing heap: ', e);
}
