import React, { useState, useEffect } from 'react';
import RemoteConfigContext from './RemoteConfigContext';
import logger from '../services/logger.js';

interface Props {
	children: React.ReactNode,
}
export default function RemoteConfigProvider(props: Props) {

	const [ready, setReady] = useState<boolean>(false);
	const [helpShowPhone, setHelpShowPhone] = useState<boolean>(false);

	useEffect(() => {
		try {
			fetch("https://www.focalityapp.com/rc-vf.json")
				.then(response => response.json())
				.then(jsonResponse => {
					logger.debug("Remote config response JSON", jsonResponse);
					setHelpShowPhone(jsonResponse.helpShowPhone === true);
					setReady(true);
				})
				.catch(e => {
					logger.error("Failed to catch or parse remote config", e);
				});
		} catch (e) {
			logger.error("Failed to catch or parse remote config", e);
		}
	}, []);

	const contextValue = {
		ready: () => ready,
		helpShowPhone: helpShowPhone,
	}

	return (
		<RemoteConfigContext.Provider value={contextValue}>
			{props.children}
		</RemoteConfigContext.Provider>
	);
}
