import React, { useEffect, useRef, useState } from 'react';
import logger from '../services/logger';
import { SwipingDirection } from './Swipable';
import BrowserFooter from './BrowserFooter';
import SwipableValues from './SwipableValues';
import { ValueGroup, ValueGroupIDs, ValueSelection } from '../App';
import HeaderPlain from './HeaderPlain';
import DialogNotched from './DialogNotched';
import { Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Plugins } from '@capacitor/core';
import Step1Complete from './Step1Complete';
import Step1Enough from './Step1Enough';
import { logEvent } from '../services/analytics';
import { getGroupByValue } from '../services/groups-helper';
import LText from '../tools/LText';

export interface BrowsingViewControllerProps {
	allValues: string[],
	primaryValues: string[],
	// valueStates: ValueStates,
	valueGroups: ValueGroup[],
	onValueUpdate(value: string, state: ValueSelection): void,
	onMenuClick(): void,
	onNextStep(): void,
	onOpenList(): void,
}

// const countCompleted = (state:ValueStates) => {
// 	const allValues = Object.keys(state);
// 	const completed = allValues.filter(value => state[value].selection !== ValueSelection.initial).length;
// 	return completed;
// }
const countCompleted = (groups: ValueGroup[]) => {
	let completed = 0;
	groups.forEach(g => {
		if (g.coreValue)
			completed++;
		completed += g.values.length;
	});
	return completed;
}

// const countState = (state:ValueStates, selection: ValueSelection) => {
// 	const allValues = Object.keys(state);
// 	const count = allValues.filter(value => state[value].selection === selection).length;
// 	return count;
// }
const countSelected = (groups:ValueGroup[]) => {
	const rejected = groups.find(g => g.id === ValueGroupIDs.rejected);
	const completed = countCompleted(groups);
	if (!rejected)
		return completed;
	else
		return completed - (rejected.coreValue ? 1 : 0) - rejected.values.length;
}

const countRejected = (groups:ValueGroup[]) => {
	const rejected = groups.find(g => g.id === ValueGroupIDs.rejected);
	if (!rejected)
		return 0;
	else
		return (rejected.coreValue ? 1 : 0) + rejected.values.length;
}

export default function BrowsingViewController(props: BrowsingViewControllerProps) {
	// const [values, setValues] = useState(props.values.map(value => ({value: value, state: ValueStates.initial})));
	// const [values, setValues] = useState(props.values.map(value => ({value: value, state: ValueStates.initial})));
	// const values = useRef(Object.keys(props.valueStates).filter(value => props.valueStates[value].selection === ValueSelection.initial));
	const values = useRef(props.allValues.filter(value => !getGroupByValue(props.valueGroups, value)));
	const primaryValuesAll = useRef(props.primaryValues.filter(v => props.allValues.indexOf(v) !== -1));
	const [showHelp, setShowHelp] = useState(false);
	const [unlockAll, setUnlockAll] = useState(countCompleted(props.valueGroups) > primaryValuesAll.current.length);

	// const allValues = Object.keys(props.valueStates);
	const allValues = props.allValues;
	const primaryValuesInitial = primaryValuesAll.current.filter(value => !getGroupByValue(props.valueGroups, value));

	// let values: string[] = [];
	// useEffect(() => {
	// 	values = props.values;
	// }, []);

	const handleSwipe = (value: string, direction: SwipingDirection) => {
		logger.debug("Swipe", value, direction);
		if (direction === SwipingDirection.left) {
			logger.info(value + " rejected");
			props.onValueUpdate(value, ValueSelection.rejected);
			logEvent("value_reject", {
				value_name: value,
				rejected: countRejected(props.valueGroups),
			});
		} else {
			logger.info(value + " accepted");
			props.onValueUpdate(value, ValueSelection.selected);
			logEvent("value_select", {
				value_name: value,
				selected: countSelected(props.valueGroups),
			});
		}
	}

	useEffect(() => {
		Plugins.Storage.get({key: "valuesfinder.step1Seen"}).then(data => {
			if (!data.value)
				setShowHelp(true);
		});
	}, []);


	const handleStep1Close = () => {
		Plugins.Storage.set({key: 'valuesfinder.step1Seen', value: JSON.stringify(new Date())});
		setShowHelp(false);
	}

	// logger.debug("Values & ValueStates", props.values, props.valueStates);
	// logger.debug("ValuesRef", values.current);
	
	// const completed = allValues.filter(value => props.valueStates[value].selection !== ValueSelection.initial).length;
	const completed = countCompleted(props.valueGroups);
	logger.debug("Completed condition", completed >= values.current.length, completed, values.current.length);

	const valuesToSwipe = unlockAll ? values.current : primaryValuesInitial;
	const progressMax = unlockAll ? allValues.length : primaryValuesAll.current.length;
	const progressCompleted = completed;

	logger.debug("primaryValuesAll, valuesToSwipe, max, completed", primaryValuesAll, valuesToSwipe, progressMax, progressCompleted);

	return (
		<>
			<HeaderPlain onMenuClick={props.onMenuClick} onHelpClick={() => setShowHelp(true)}></HeaderPlain>
			{(progressCompleted < progressMax) &&
				<SwipableValues values={valuesToSwipe} onSwipe={handleSwipe}/>
			}
			{(progressCompleted >= progressMax) && !unlockAll &&
				// <Step1Complete onContinue={props.onNextStep}/>
				<Step1Enough
					numPrimary={primaryValuesAll.current.length}
					numFurther={allValues.length - progressCompleted}
					onContinue={() => {setUnlockAll(true); logEvent("browse_full");}}
					onGroup={() => {props.onNextStep(); logEvent("browse_shortcut")}}
				/>
			}
			{(progressCompleted >= progressMax) && unlockAll &&
				<Step1Complete onContinue={props.onNextStep}/>
			}
			{/* <Box marginLeft="auto" marginRight="auto" maxWidth="70%" textAlign="center" display="flex" justifyContent="center" alignItems="center"> */}
			{/* <Container maxWidth="xs"> */}
			
			{/* </Container> */}
			<BrowserFooter max={progressMax} completed={progressCompleted} onListClick={props.onOpenList}/>
			<DialogNotched
				open={showHelp}
				onClose={handleStep1Close}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogContent>
					<Typography variant="h4" gutterBottom><LText en="Step 1: Browse" de="Schritt 1: Sammeln"/></Typography>
					<Typography variant="body1" paragraph><LText en="Select every value that resonates strongly with you." de="Finde jeden Wert, der Dich stark anspricht."/></Typography>
					<Typography variant="body1" paragraph>
						<LText
							en="<b>Swipe right</b> if the value resonates deeply.{br}
								<b>Swipe left</b> to skip it."
							de="<b>Wische nach rechts</b>, wenn Dich der Wert anspricht.{br}
								<b>Wische nach links</b>, um ihn zu überspringen."
						/>
					</Typography>
					<Typography variant="body1" paragraph><LText en="Trust your gut. Don’t overthink your choices. You’ll know when a value resonates. If not – it's probably not one that you hold closely." de="Denke nicht zu lange nach. Du wirst wissen, wenn ein Wert zu Dir passt. Wenn nicht, ist es wahrscheinlich keiner, der Dir besonders am Herzen liegt."/></Typography>
					<Typography variant="body1" paragraph><LText en="Skipping a value does not mean that you don’t appreciate it. Skipping just means that your other chosen values are higher priorities." de="Einen Wert zu überspringen bedeutet nicht, dass Du ihn nicht gut findest - die anderen gewählten Werte passen einfach noch besser."/></Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleStep1Close}>
						<LText en="Close" de="Schließen"/>
					</Button>
				</DialogActions>
			</DialogNotched>
		</>
	);
}
