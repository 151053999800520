import { createMuiTheme } from '@material-ui/core/styles';

// https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=546E7A&secondary.color=c2185b

const purposeTheme = createMuiTheme({
	palette: {
		primary: {
			// light: '#819ca9',
			main: '#20124d',
			// dark: '#29434e',
			contrastText: '#fafafa',
		},
		secondary: {
			// light: '#fa5788',
			main: '#ea2af3',
			// dark: '#8c0032',
			contrastText: '#fff',
		},
	},
});

export default purposeTheme;