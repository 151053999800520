import React, { useState, useEffect } from 'react';
import logger from '../services/logger';
import LangContext from './LangContext';
import { Plugins } from '@capacitor/core';
import { negotiateLanguages } from '@fluent/langneg';

interface Props {
	lang?: string,
	children: React.ReactNode,
}
export default function LangProvider(props: Props) {

	const [lang, setLang] = useState("en");
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		Plugins.Device.getLanguageCode().then(languageCode => {
			const l = languageCode.value;
			logger.debug("Detected language:", l);
			const supported = negotiateLanguages([l], ["en", "de"], {
				strategy: 'lookup',
				defaultLocale: 'en',
			});
			const selectedLanguage = supported[0] === "de" ? "de" : "en";
			logger.info('Setting language to '+selectedLanguage);
			setLang(selectedLanguage);
			setInitialized(true);
		});
	}, []);

	const contextValue = {
		initialized: initialized,
		// lang: props.lang || lang,
		lang: "en",
		// setLang: (lang: string) => setLang(lang),
	}

	logger.debug("Updated LangProvider", contextValue);

	return (
		<LangContext.Provider value={contextValue}>
			{props.children}
		</LangContext.Provider>
	);
}
