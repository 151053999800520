import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import logger from './services/logger';
import BrowsingViewController from './components/BrowsingViewController';
import GroupingViewController from './components/GroupingViewController';
import loadValuesEN, { loadPrimaryValuesEN, loadPrimaryValuesDE, loadValuesDE } from './services/value-loader';
import purposeTheme from '../src/theme-pathly.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MainMenu from './components/MainMenu';
import LoadingView from './components/LoadingView';
import { Plugins } from '@capacitor/core';
import OnboardingPrinciple from './components/OnboardingPrinciple';
import ListViewController from './components/ListViewController';
import { logEvent, setScreenName } from './services/analytics';
import { getGroupByValue } from './services/groups-helper';
import HelpView from './components/HelpView';
import HeaderPlain from './components/HeaderPlain';
import RemoteConfigContext from './contexts/RemoteConfigContext';
import LangContext from './contexts/LangContext';

export interface ValueStates {
	[key: string]: ValueState,
}

interface ValueState {
	selection: ValueSelection,
	group: string | null,
	groupIndex: number,
	coreValue: boolean,
}

export interface ValueGroup {
	id: string,
	values: string[],
	coreValue: string | null,
	title: string,
}

export enum ValueSelection {
	initial,
	selected,
	rejected,
}

export enum ValueGroupIDs {
	ungrouped = "_ungrouped",
	rejected = "_rejected",
}

enum View {
	onboarding = "onboarding",
	browse = "browse",
	list = "list",
	group = "group",
	help = "help",
}

// const sampleState = {"Honesty":{"selection":2,"group":"foo1","coreValue":false},"Excellence":{"selection":1,"group":null,"coreValue":false},"A Com­fort­able Life\r":{"selection":1,"group":null,"coreValue":false}};

function App() {

	// const values = useRef(["Honesty", "Excellence"].concat(loadValues()));
	const [values, setValues] = useState<null|string[]>(null);
	const [valuesPrimary, setValuesPrimary] = useState<null|string[]>(null);
	// const [valueStates, setValueStates] = useState<ValueStates>({});
	// const [valueStates, setValueStates] = useState<ValueStates|null>(null);
	const [showMenu, setShowMenu] = useState(false);
	const [view, setView] = useState(View.onboarding);
	const [onboardingDone, setOnboardingDone] = useState<boolean|null>(null);
	const [valueGroups, setValueGroups] = useState<null|ValueGroup[]>(null);
	const remoteConfig = useContext(RemoteConfigContext);
	const lang = useContext(LangContext);

	useEffect(() => {
		if (lang.initialized) {
			if (lang.lang === "de") {
				setValues(loadValuesDE());
				setValuesPrimary(loadPrimaryValuesDE());
			} else {
				setValues(loadValuesEN());
				setValuesPrimary(loadPrimaryValuesEN());
			}
		}
	}, [lang.initialized, lang.lang]);

	useEffect(() => {
		// Plugins.Storage.get({key: "valuesfinder.valuestates"}).then(data => {
		// 	logger.debug('Stored values state:', data);
		// 	if (data.value) {
		// 		const state = JSON.parse(data.value);
		// 		setValueStates(state);
		// 	} else {
		// 		setValueStates({});
		// 	}
		// });
		Plugins.Storage.get({key: "valuesfinder.valuegroups"}).then(data => {
			logger.debug('Stored value groups:', data);
			if (data.value) {
				const jsonGroups = JSON.parse(data.value);
				const groups: ValueGroup[] = jsonGroups.map((g: any) => {
					const group: ValueGroup = {
						id: g.id,
						values: g.values,
						coreValue: g.coreValue,
						title: g.title || "",
					};
					// Former core values are no longer a special class.
					// Have been replaced by freely editable titles.
					// => convert to title + plain value
					if (group.coreValue) {
						logger.info("Converting core value into plain value: " + group.coreValue);
						if (group.title === "")
							group.title = group.coreValue;
						group.values.push(group.coreValue);
						group.coreValue = null;
					}
					return group;
				});
				setValueGroups(groups);
			} else {
				setValueGroups([]);
			}
		});
		Plugins.Storage.get({key: "valuesfinder.onboardingDone"}).then(data => {
			if (data.value) {
				setView(View.browse);
				setOnboardingDone(true);
			} else {
				setOnboardingDone(false);
			}
		});
	}, []);

	// useEffect(() => {
	// 	if (valueStates) {
	// 		logger.debug("Storing value state...");
	// 		Plugins.Storage.set({key: 'valuesfinder.valuestates', value: JSON.stringify(valueStates)});
	// 	}
	// }, [valueStates]);

	useEffect(() => {
		if (valueGroups && valueGroups.length > 0) {
			logger.debug("Storing value groups...");
			Plugins.Storage.set({key: 'valuesfinder.valuegroups', value: JSON.stringify(valueGroups)});
		} else {
			logger.debug("Not storing value groups: Empty");
		}
	}, [valueGroups]);


	const handleValueUpdate = (value: string, state: ValueSelection) => {
		// setValueStates(currentStates => {
		// 	const curr = currentStates && currentStates[value] ? currentStates[value] : {selection: ValueSelection.initial, group: null, coreValue: false};
		// 	// const curr = currentStates[value] || {selection: ValueSelection.initial, group: null, coreValue: false};
		// 	const updatedValueStates = Object.assign({}, valueStates, {
		// 		[value]: {
		// 			selection: state,
		// 			group: curr.group,
		// 			coreValue: curr.coreValue,
		// 		},
		// 	});
		// 	return updatedValueStates;
		// });
		logger.debug("HandleValueUpdate", value, state);
		if (state === ValueSelection.rejected) {
			doChangeGroup(value, ValueGroupIDs.rejected, false, 0);
		} else if (state === ValueSelection.initial) {
			doChangeGroup(value, null, false, 0);
		} else {
			const currentGroup = valueGroups ? getGroupByValue(valueGroups, value) : undefined;
			if (!currentGroup || currentGroup.id === ValueGroupIDs.rejected) {
				doChangeGroup(value, ValueGroupIDs.ungrouped, false, 0);
			} else {
				logger.debug("no change group", currentGroup);
			}
		}
	}

	const handleMenuViewChange = (selected: View) => {
		setView(selected);
		setShowMenu(false);
	}

	const doChangeGroup = (value: string, group: string | null, isCore: boolean, index: number) => {
		logger.debug("doChangeGroup", value, group, isCore, index);
		// setValueStates(current => {
		// 	const currentState = current && current[value] ? current[value] : {selection: ValueSelection.initial, group: null, coreValue: false};
		// 	const updatedState = Object.assign({}, currentState, {
		// 		group: group,
		// 		coreValue: isCore,
		// 	});
		// 	const updatedStates = Object.assign({}, current, {
		// 		[value]: updatedState,
		// 	});
		// 	logger.debug("Updating state", updatedStates);
		// 	return updatedStates;
		// });
		setValueGroups(currentGroups => {
			const updatedGroups = !currentGroups ? [] : currentGroups.map(g => {
				const updatedGroup:ValueGroup = Object.assign({}, g, {
					values: g.values.filter(v => v !== value),
					coreValue: g.coreValue !== value ? g.coreValue : null,
				});
				if (group && updatedGroup.id === group) {
					if (isCore) {
						updatedGroup.coreValue = value;
					} else {
						updatedGroup.values.splice(index, 0, value);
					}
				}
				return updatedGroup;
			}).filter(g => g.coreValue || g.values.length > 0);
			if (!updatedGroups.find(g => g.id === group) && group) {
				updatedGroups.push({
					id: group,
					values: !isCore ? [value] : [],
					coreValue: isCore ? value : null,
					title: "",
				});
			}
			logger.debug("Updated groups:", updatedGroups);
			return updatedGroups;
		});

		logEvent("group_change", {
			value_name: value,
			is_core: isCore ? "true" : "false",
		});
	};

	const doChangeGroupTitle = (groupId: string, title: string) => {
		setValueGroups(currentGroups => !currentGroups ? [] : currentGroups.map(g => Object.assign({}, g, {title: g.id === groupId ? title : g.title})));
	}

	const handleOnboardingDone = () => {
		setView(View.browse);
		Plugins.Storage.set({key: 'valuesfinder.onboardingDone', value: JSON.stringify(new Date())});
	}

	// const valueStatesComplete = Object.assign({}, ...values.current.map(value => ({[value]: 
	// 	{
	// 		selection: valueStates && valueStates[value] ? valueStates[value].selection : ValueSelection.initial,
	// 		group: valueStates && valueStates[value] ? valueStates[value].group : null,
	// 		coreValue: valueStates && valueStates[value] ? valueStates[value].coreValue : false,
	// }})));


	// Track screen views
	useEffect(() => {
		const timer = setTimeout(() => {
			setScreenName(view);
		}, 1000);
		return () => {
			clearTimeout(timer);
		}
	}, [view]);

	if (!valueGroups || onboardingDone === null || !values || !valuesPrimary)
		return (
		// <MuiThemeProvider theme={purposeTheme}>
			// <CssBaseline />
			<LoadingView/>
		// </MuiThemeProvider>
		);
	else
		return (
			<>
				<MuiThemeProvider theme={purposeTheme}>
					<CssBaseline />
					{showMenu && 
						<MainMenu
							onClose={() => setShowMenu(false)}
							onClickBrowse={() => handleMenuViewChange(View.browse)}
							onClickGroup={() => handleMenuViewChange(View.group)}
							onClickList={() => handleMenuViewChange(View.list)}
							onClickHelp={() => handleMenuViewChange(View.help)}
						/>
					}
					
					{view === View.onboarding && 
						<OnboardingPrinciple onDone={handleOnboardingDone}/>
					}
					{view === View.browse && 
						<BrowsingViewController
							allValues={values}
							primaryValues={valuesPrimary}
							// valueStates={valueStatesComplete}
							valueGroups={valueGroups}
							onValueUpdate={handleValueUpdate}
							onMenuClick={() => setShowMenu(true)}
							onNextStep={() => setView(View.group)}
							onOpenList={() => setView(View.list)}
						/>
					}

					{view === View.list && 
						<ListViewController
							values={values}
							primaryValues={valuesPrimary}
							// valueStates={valueStatesComplete}
							valueGroups={valueGroups}
							onValueUpdate={handleValueUpdate}
							onMenuClick={() => setShowMenu(true)}
						/>
					}
					
					{view === View.group &&
						<GroupingViewController
							values={values}
							groups={valueGroups}
							onMenuClick={() => setShowMenu(true)}
							doChangeGroup={doChangeGroup}
							doChangeGroupTitle={doChangeGroupTitle}
						/>
					}

					{view === View.help && <>
						<HeaderPlain onMenuClick={() => setShowMenu(true)} onHelpClick={() => {}}/>
						<HelpView
							showPhoneNumber={remoteConfig.helpShowPhone}
						/>
					</>}
				</MuiThemeProvider>
			</>
		);
}

export default App;
