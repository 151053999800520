import React from 'react';

export interface LangCtx {
	initialized: boolean,
	lang: string,
}

const LangContext = React.createContext<LangCtx>({
	initialized: false,
	lang: "en",
});

export default LangContext;
