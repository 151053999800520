import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Chip, Divider, useMediaQuery } from '@material-ui/core';
// import logger from '../services/logger';
import { Draggable, Droppable } from 'react-beautiful-dnd';


const useStyles = makeStyles(theme => ({
	root: {
		// overflow: "auto",
		// flexGrow: 1,
		// display: "inline-flex",
		overflow: "scroll",
		whiteSpace: "nowrap",
		// minHeight: 100,
		// marginBottom: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	container: {
		width: "100%",
		position: "fixed",
		backgroundColor: theme.palette.background.default,
		zIndex: 1,
	},
}));

export interface UngroupedCardsProps {
	values: string[],
}

export default function UngroupedCards(props: UngroupedCardsProps) {

	const classes = useStyles();
	const matches = useMediaQuery('(min-width:600px)');

	// const values = ["Capability","Communication","Continuous Improvement","Focus on Future", "Making a Difference"];
	const values = props.values;

	if (!values.length)
	return null;

	return (
		<>
		<div 
			// style={{display: "flex",flexDirection: "column"}}
			className={classes.container}
			>
		{/* <Box> */}
			<Droppable droppableId="_ungrouped" direction="horizontal">
				{provided => (
					<div ref={provided.innerRef} {...provided.droppableProps} className={classes.root} style={{minHeight: values.length > 0 ? 40 : undefined}}>
						{values.map((item, index) => 
							<Draggable key={item} draggableId={item} index={index}>
								{provided => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{display: "inline-block", ...provided.draggableProps.style}}
										// style={{display: "inline", ...provided.draggableProps.style}}
										// style={{...provided.draggableProps.style}}
									>
										<Chip label={item} avatar={<Avatar>{item.substr(0,1)}</Avatar>} className={classes.chip}/>
									</div>
								)}
							</Draggable>
						)}
						{provided.placeholder}
						{/* <Chip label={"18"} avatar={<Avatar>+</Avatar>} className={classes.chip}/> */}
					</div>
				)}
			</Droppable>
			{/* </Box> */}
			<Box maxWidth={matches ? "600px" : undefined}>
				<Divider light />
				{/* <Box textAlign="right" marginTop={1} marginRight={2} marginBottom={2}><strong>{values.length}</strong> values remaining</Box> */}
			</Box>
		</div>
		<div style={{height: 48}}></div>
		</>
	);
}
