import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import FlareIcon from '@material-ui/icons/Flare';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Divider, Typography } from '@material-ui/core';
import TocIcon from '@material-ui/icons/Toc';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LText from '../tools/LText';
import LangContext from '../contexts/LangContext';
// import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CallMadeIcon from '@material-ui/icons/CallMade';
const buildMeta = require('../build-metadata.json');

const useStyles = makeStyles(theme => ({
	buildInfo: {
		padding: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingBottom: "env(safe-area-inset-bottom)",
		position: "fixed",
		bottom: 0,
	},
	list: {
		paddingRight: theme.spacing(2),
		paddingTop: "env(safe-area-inset-top)",
		paddingLeft: "env(safe-area-inset-left)",
	},
	list2: {
		paddingRight: theme.spacing(2),
	},
}));

// function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
function ListItemLink(props: any) {
	return <ListItem button component="a" {...props} />;
 }

export interface MainMenuProps {
	onClose(): void,
	onClickBrowse(): void,
	onClickGroup(): void,
	onClickList(): void,
	onClickHelp(): void,
}

export default function MainMenu(props: MainMenuProps) {
	const classes = useStyles();
	const lang = useContext(LangContext);

	const buildDate = new Date(buildMeta.time);
	const buildDateStr = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false,}).format(buildDate);

	return <>
		<Drawer anchor="left" open={true} onClose={props.onClose}>
			<List className={classes.list}>
				<ListItem button onClick={props.onClickBrowse}>
					<ListItemIcon>{<ViewCarouselIcon/>}</ListItemIcon>
					<ListItemText primary={<LText en="Browse Values" de="Sammeln"/>} />
				</ListItem>
				<ListItem button onClick={props.onClickList}>
					<ListItemIcon>{<TocIcon/>}</ListItemIcon>
					<ListItemText primary={<LText en="List Values" de="Auflisten"/>} />
				</ListItem>
				<ListItem button onClick={props.onClickGroup}>
					<ListItemIcon>{<ViewAgendaIcon/>}</ListItemIcon>
					<ListItemText primary={<LText en="Group Values" de="Gruppieren"/>} />
				</ListItem>
				{/* <ListItem button>
					<ListItemIcon>{<Avatar className={classes.avatar}>1</Avatar>}</ListItemIcon>
					<ListItemText primary="Browse Values" />
				</ListItem>
				<ListItem button>
					<ListItemIcon>{<Avatar className={classes.avatar}>2</Avatar>}</ListItemIcon>
					<ListItemText primary="Group Values" />
				</ListItem> */}
			</List>
			{/* <Divider />
			<List className={classes.list2}>
				<ListItem button onClick={props.onClickHelp}>
					<ListItemIcon>{<HelpOutlineIcon />}</ListItemIcon>
					<ListItemText primary={<LText en="Help" de="Hilfe"/>} />
				</ListItem>
				<ListItemLink href={lang.lang === "de" ? "https://www.focalityapp.com/de/values-finder-coaching-tool/" : "https://www.focalityapp.com/en/values-finder-coaching/"} target="_blank">
					<ListItemIcon>{<SupervisedUserCircleIcon/>}</ListItemIcon>
					<ListItemText primary={<><LText en="For Coaches" de="Für Coaches"/>&nbsp;<CallMadeIcon fontSize="small" style={{verticalAlign: "middle", color: "rgba(0, 0, 0, 0.54)"}}/></>} />
				</ListItemLink>
			</List> */}
			<Typography variant="caption" className={classes.buildInfo}>Built {buildDateStr}</Typography>
		</Drawer>
	</>
}
