import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Chip } from '@material-ui/core';
// import logger from '../services/logger';
// import { Draggable, Droppable } from 'react-beautiful-dnd';


const useStyles = makeStyles(theme => ({
	root: {
		// overflow: "auto",
		// flexGrow: 1,
		// display: "inline-flex",
		overflow: "hidden",
		whiteSpace: "nowrap",
		// minHeight: 100,
		// marginBottom: theme.spacing(2),
		// paddingLeft: theme.spacing(2),
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	container: {
		// width: "100%",
		// position: "fixed",
		backgroundColor: theme.palette.background.default,
		// zIndex: 1,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(3),
	},
	label: {
		position: "relative", // required for html2canvas
	},
}));

export interface UngroupedCardsProps {
	values: string[],
}

export default function UngroupedCardsStatic(props: UngroupedCardsProps) {

	const classes = useStyles();

	// const values = ["Capability","Communication","Continuous Improvement","Focus on Future", "Making a Difference"];
	const values = props.values;

	if (!values.length)
	return null;

	return (
		<>
		<div 
			// style={{display: "flex",flexDirection: "column"}}
			className={classes.container}
			>
		{/* <Box> */}
			
						<div style={{minHeight: values.length > 0 ? 40 : undefined}}>
							{values.map((item, index) => 

										<div
											key={item}
											style={{display: "inline-block"}}
											// style={{display: "inline", ...provided.draggableProps.style}}
											// style={{...provided.draggableProps.style}}
										>
											<Chip label={item} avatar={<Avatar>{item.substr(0,1)}</Avatar>} className={classes.chip} classes={{label:classes.label}}/>
										</div>

							)}
						</div>

			{/* </Box> */}
			{/* <Divider light /> */}
		</div>
		{/* <div style={{height: 48}}></div> */}
		</>
	);
}
