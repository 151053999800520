import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {registerWebPlugin} from "@capacitor/core";
import {FileSharer} from '@byteowls/capacitor-filesharer';
import RemoteConfigProvider from './contexts/RemoteConfigProvider';
import LangProvider from './contexts/LangProvider';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN === "null" ? undefined : process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <RemoteConfigProvider>
      <LangProvider>
        <App />
      </LangProvider>
    </RemoteConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

registerWebPlugin(FileSharer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

