import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	chip: {
		// marginRight: theme.spacing(1),
		// marginBottom: theme.spacing(1),
		// color: "white",
		transition: (props: ValueChipProps) => props.disableTransitions ? "none" : "background-color 0.5s, color 0.5s",
		// transition: "none", 
		height: (props:ValueChipProps) => props.variant === "title" ? theme.spacing(4) : undefined,
		// padding: (props:ValueChipProps) => props.variant === "title" ? theme.spacing(1) : undefined,
		fontSize: (props:ValueChipProps) => props.variant === "title" ? "1.1rem" : undefined,
		fontWeight: (props:ValueChipProps) => props.variant === "title" ? 300 : undefined,
		borderRadius: (props:ValueChipProps) => props.variant === "title" ? 24 : undefined,
	},
	label: {
		position: "relative", // required for html2canvas
	},
	avatar: {
		fontSize: (props:ValueChipProps) => props.variant === "title" ? "0.9rem !important" : undefined,
		height: (props:ValueChipProps) => props.variant === "title" ? "24px !important" : undefined,
		width: (props:ValueChipProps) => props.variant === "title" ? "24px !important" : undefined,
	},
}));

export interface ValueChipProps {
	value: string,
	variant: "default" | "dragging" | "title",
	disableTransitions?: boolean,
}

export default function ValueChip(props: ValueChipProps) {

	const classes = useStyles(props);

	const textColor = props.variant === "dragging" ? "black" : "white";

	return (
		<Chip
			label={props.value}
			color={props.variant === "title" ? "secondary" : props.variant === "default" ? "primary" : undefined}
			avatar={<Avatar style={{color: textColor}} className={classes.avatar}>{props.value?.substr(0,1)}</Avatar>}
			className={classes.chip}
			classes={{label: classes.label}}
			style={{color: textColor}}
		/>
	);
}
