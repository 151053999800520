import * as log from 'loglevel';
// import { logEvent } from './analytics';

log.setDefaultLevel(typeof STORYBOOK_ENV !== 'undefined' ? 'debug' : process.env.REACT_APP_LOGLEVEL);

function logError() {
	log.error.apply(this, arguments);
	// logEvent("app_error", {
	// 	message: String(arguments[0]),
	// });
}

const logger = {
	trace: log.trace,
	debug: log.debug,
	info: log.info,
	warn: log.warn,
	error: logError,
}

export default logger;