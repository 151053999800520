import React, { useRef, useState } from 'react'
//@ts-ignore
import { useSpring, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import logger from '../services/logger';
// import '../CardSwiper.css'
// import logger from '../services/logger';

export enum SwipingDirection {
	left = "left",
	right = "right",
}

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i:number) => ({
	x: 0,
	y: i * -4,
	scale: 1,
	// rot: -5 + Math.random() * 10,
	rot: 0,
	delay: i * 100 
});
const from = (i:unknown) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
// const trans = (r:number, s:unknown) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`
const trans = (r:number, s:unknown) => `rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

export interface SwipableProps {
	children?: React.ReactNode,
	// items: React.ReactNode[],
	onGone?(): void,
	onGoing?(direction: SwipingDirection): void,
	// item: React.ReactNode,
	item: React.ReactElement,
	directionRef?: React.MutableRefObject< -1 | 0 | 1 >,
}
export default function Swipable(mainProps: SwipableProps) {

	// const [gone, setGone] = useState(false);
	const gone = useRef(false);
	const [rip, setRip] = useState(false);
	const swipingDirectionRef = useRef< -1 | 0 | 1 >(0);
	const [swipingDirection, setSwipingDirection] = useState(0);

	const handleRest = () => {
		if (gone.current) {
			setRip(true);
			if (mainProps.onGone)
				mainProps.onGone();
		}
	}

	// logger.debug("Item", mainProps.item);

	// const cards = mainProps.items;
	// const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
	// const [props, set] = useSprings(cards.length, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
	const [animatedProps, set] = useSpring(() => ({...to(0), from: from(0), onRest: handleRest})) // Create a bunch of springs using the helpers above
	
	// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
	const bind = useGesture(({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
		// console.log("Gesture", down, velocity, xDir, distance, xDelta);
		const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
		const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right

		if (down && mainProps.directionRef && dir !== mainProps.directionRef.current) {
			mainProps.directionRef.current = dir;
		} else if (!down && mainProps.directionRef && mainProps.directionRef.current !== 0) {
			mainProps.directionRef.current = 0;
		}

		if (down && dir !== swipingDirectionRef.current) {
			swipingDirectionRef.current = dir;
		} else if (!down && swipingDirectionRef.current !== 0) {
			swipingDirectionRef.current = 0;
		}

		const dir2 = xDelta > 0 ? 1 : xDelta < 0 ? -1 : 0;
		if (down && dir2 !== swipingDirection) {
			setSwipingDirection(dir2);
		} else if (!down && swipingDirection !== 0) {
			setSwipingDirection(0);
		}

		let isGone = gone.current;
		if (!down && trigger) {
			logger.debug("Swiped", dir2, xDelta, velocity);
			gone.current = true; // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
			isGone = true;
			if (mainProps.onGoing)
				mainProps.onGoing(dir2 === 1 ? SwipingDirection.right : SwipingDirection.left);
		}
		//@ts-ignore
		// set(i => {
			// if (index !== i) return // We're only interested in changing spring-data for the current spring
			const x = isGone ? (200 + window.innerWidth) * dir2 : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
			const rot = xDelta / 100 + (isGone ? dir2 * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
			const scale = down ? 1.1 : 1 // Active cards lift up a bit
			// return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : gone ? 200 : 500 } }
		// })
		set({ x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } });
		// if (!down && gone.size === cards.length) setTimeout(() => gone.clear() || set(i => to(i)), 600)
	});

	const slideOut = (direction: -1 | 1) => {
		const x = (200 + window.innerWidth) * direction; // When a card is gone it flys out left or right, otherwise goes back to zero
		const rot = 100 * direction;
		const scale = 1;

		if (mainProps.onGoing)
			mainProps.onGoing(direction === -1 ? SwipingDirection.left : SwipingDirection.right);

		set({ x, rot, scale, delay: undefined, config: { friction: 50, tension: 200 } });
	};
	
	// Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
	if (rip)
		return null;
	else {

		// const childrenWithProps = React.Children.map(mainProps.children, child => {
		// 	// checking isValidElement is the safe way and avoids a typescript error too
		// 	const props = Object.assign({}, child?.props, { direction: 1 });
		// 	if (React.isValidElement(child)) {
		// 		return React.cloneElement(child, props);
		// 	}
		// 	return child;
		// });

		return (
		<>
		{/* <div className="CardSwiper"> */}
			{/* {animatedProps.map(({ x, y, rot, scale }, i) => ( */}
				<animated.div style={{ transform: interpolate([animatedProps.x, animatedProps.y], (x:number, y:number) => `translate3d(${x}px,${y}px,0)`) }}>
					{/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
					<animated.div {...bind()} style={{ transform: interpolate([animatedProps.rot, animatedProps.scale], trans), padding: "20px" }} >
						{/* {cards[i]} */}
						{React.cloneElement(mainProps.item, {
							swipingDirection: swipingDirection,
							onLeftClick: () => slideOut(-1),
							onRightClick: () => slideOut(1),
						})}
					</animated.div>
				</animated.div>
			{/* ))} */}
		{/* </div> */}
		</>
		);
	}
}
