import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { ValueGroup } from '../App';
import GroupCardStatic from './GroupCardStatic';
import VFContainer from './VFContainer';
import UngroupedCardsStatic from './UngroupedCardsStatic';
import LText from '../tools/LText';
import ValueGrouperFooterPathly from './ValueGrouperFooterPathly';


const useStyles = makeStyles(theme => ({
	group: {
		// minHeight: 100,
		// backgroundColor: "lightgrey",
	},
	root: {
		// marginTop: theme.spacing(4),
		// background: "lightblue", 
		// top: 0,
		// position: "relative",
	},
}));

// export interface ValueGroup {
// 	id: string,
// 	values: string[],
// 	titleValue: string | null,
// }

export interface ValueGrouperProps {
	groups: ValueGroup[],
	ungroupedValues: string[],
	isPreview: boolean,
}

export default function ValueGrouperStatic(props: ValueGrouperProps) {

	const classes = useStyles();

	// const [unsorted, setUnsorted] = useState();
	// const [group1, setGroup1] = useState(["yo", "dude"]);

	const groupsSorted = props.groups.sort((a, b) => {
		// if (!a.id)
		// 	return -1;
		// if (!b.id)
		// 	return 1;
		if (a.id < b.id)
			return -1;
		else if (a.id === b.id)
			return 0;
		else
			return 1;
	});

	

	return (
		<>
			<Box className={classes.root} paddingBottom={4}>
				<VFContainer>
		
					<Box textAlign="center" marginBottom={4} paddingTop={4}>
						<Typography variant="h4"><LText en="My Core Values" de="Meine Werte"/></Typography>
					</Box>

					{props.isPreview &&
						<div style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%) rotate(-45deg)",
							opacity: 0.5,
							zIndex: 9999,
						}}> 
							<Typography variant="h1">PREVIEW</Typography>
						</div>
					}
			
					<UngroupedCardsStatic values={props.ungroupedValues}/>

					{/* {props.groups.sort().map(group =>  */}
					{groupsSorted.map(group => 
						<GroupCardStatic key={group.id} id={group.id} values={group.values} titleValue={group.coreValue} title={group.title}/>
					)}

					<ValueGrouperFooterPathly/>
				</VFContainer>
			</Box>
		</>
	);
}
