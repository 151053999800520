import React, { useEffect, useState } from 'react';
import logger from '../services/logger';
import { ValueGroup, ValueGroupIDs } from '../App';
import ValueGrouper from './ValueGrouper';
import HeaderPlain from './HeaderPlain';
import DialogNotched from './DialogNotched';
import { Box, Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Plugins } from '@capacitor/core';
import { logEvent } from '../services/analytics';
import GroupExporter from './GroupExporter';
import VFContainer from './VFContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import LText from '../tools/LText';

export enum ExportType {
	download,
	share,
}

export enum DoneNoticeState {
	loading = "loading",
	notshown = "notshown",
	showing = "showing",
	shown = "shown",
}

export interface GroupingViewControllerProps {
	values: string[],
	// valueStates: ValueStates,
	groups: ValueGroup[],
	// onValueUpdate(value: string, state: ValueState): void,
	onMenuClick(): void,
	doChangeGroup(value: string, group: string | null, isCore: boolean, index: number): void,
	doChangeGroupTitle(groupId: string, title: string): void,
}

export default function GroupingViewController(props: GroupingViewControllerProps) {

	const [showHelp, setShowHelp] = useState(false);
	// const [bannerDismissed, setBannerDismissed] = useState(false);
	const [exporting, setExporting] = useState<null|ExportType>(null);
	const [notready, setNotready] = useState(false);
	const [doneNoticeState, setDoneNoticeState] = useState(DoneNoticeState.loading);
	// logger.debug("ValueStates", props.valueStates);

	//@ts-ignore
	// const canShare = navigator.canShare && navigator.canShare({ files: [new File([""], "test")] });
	const canShare = false;


	useEffect(() => {
		Plugins.Storage.get({key: "valuesfinder.step2Seen"}).then(data => {
			if (!data.value)
				setShowHelp(true);
		});
	}, []);

	const handleStep2Close = () => {
		Plugins.Storage.set({key: 'valuesfinder.step2Seen', value: JSON.stringify(new Date())});
		setShowHelp(false);
	}


	useEffect(() => {
		Plugins.Storage.get({key: "valuesfinder.doneNoticeShown"}).then(data => {
			if (!data.value)
				setDoneNoticeState(DoneNoticeState.notshown);
			else
				setDoneNoticeState(DoneNoticeState.shown);
		});
	}, []);

	// const groups = [
	// 	{
	// 		id: "foo1",
	// 		values: ["Originality", "Resourcefulness", "Smartness", "Stimulation", "Ingenuity"],
	// 		titleValue: "Creativity",
	// 	},
	// 	{
	// 		id: "foo2",
	// 		values: ["Love","Positivity", "Friendship", "Fairness"],
	// 		titleValue: null,
	// 	},
	// ];

	// const groups: ValueGroup[] = [];
	const groups = props.groups.filter(g => g.id !== ValueGroupIDs.ungrouped && g.id !== ValueGroupIDs.rejected);
	const groupUngrouped = props.groups.find(g => g.id === ValueGroupIDs.ungrouped);
	const ungrouped: string[] = groupUngrouped ? groupUngrouped.values : [];
	const selectedValues: string[] = [];
	props.groups.forEach(g => {
		if (g.id !== ValueGroupIDs.rejected) {
			if (g.coreValue)
				selectedValues.push(g.coreValue);
			selectedValues.push(...g.values);
		}
	});
	// Object.keys(props.valueStates).forEach(value => {
	// 	const valueState = props.valueStates[value];
	// 	if (valueState.selection === ValueSelection.selected) {
	// 		const groupId = valueState.group;
	// 		selectedValues.push(value);
	// 		if (groupId === null) {
	// 			ungrouped.push(value);
	// 		} else {
	// 			const group = groups.find(group => group.id === groupId);
	// 			if (group) {
	// 				if (valueState.coreValue) {
	// 					group.titleValue = value;
	// 				} else {
	// 					group.values.push(value);
	// 				}
	// 			} else {
	// 				groups.push({
	// 					id: groupId,
	// 					values: valueState.coreValue ? [] : [value],
	// 					titleValue: valueState.coreValue ? value : null,
	// 				});
	// 			}
	// 		}
	// 	}
	// });

	// const groupsSorted = groups.map(group => ({
	// 	id: group.id,
	// 	titleValue: group.titleValue,
	// 	values: group.values.sort(),
	// }))

	// const ungrouped = ["Capability","Communication","Continuous Improvement","Focus on Future", "Making a Difference"];
	// const ungrouped = Object.keys(props.valueStates).filter(value => props.valueStates[value].group === null);
	logger.debug("Ungrouped: ", ungrouped);

	const doChangeGroup	= (value: string, group: string | null, isCore: boolean, index: number) => {
		props.doChangeGroup(value, group, isCore, index);
	};

	useEffect(() => {
		if (groups.length > 0 && ungrouped.length === 0) {
			logEvent("grouping_ungrouped_done", {
				value_count: selectedValues.length,
				values_names: selectedValues.join(),
			});
		}
	}, [ungrouped.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (groups.length > 0 && ungrouped.length === 0) {
			const titleless = groups.find(group => group.title === "");
			if (!titleless) {
				if (doneNoticeState === DoneNoticeState.notshown) {
					setDoneNoticeState(DoneNoticeState.showing);
					Plugins.Storage.set({key: 'valuesfinder.doneNoticeShown', value: JSON.stringify(new Date())});
					logEvent("grouping_core_done", {
						value_count: selectedValues.length,
						values_names: selectedValues.join(),
						values_cores: groups.map(group => group.coreValue).join(),
						values_group_count: groups.length,
					});
				}
			}
		}
	}, [doneNoticeState, groups, selectedValues, ungrouped.length]); 

	useEffect(() => {
		logEvent("grouping_start", {
			values_count: selectedValues.length,
			values_names: selectedValues.join(),
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleDownload = () => {
		setExporting(ExportType.download);
		if (doneNoticeState === DoneNoticeState.showing)
			setDoneNoticeState(DoneNoticeState.shown);
		logEvent("download");
	}

	const handleShare = () => {
		setExporting(ExportType.share);
		logEvent("share");
	}

	const handleNotReady = (variant: string) => {
		setNotready(true);
		logEvent(variant+"_notready");
	}

	return (
		<>
			
			{/* <Header title="Group Values" onMenuClick={props.onMenuClick}></Header> */}
			<HeaderPlain onMenuClick={props.onMenuClick} onHelpClick={() => {setShowHelp(true)}} centerElement={(
				<>
					<Box onClick={handleDownload} display="inline-block">
						<Button
							variant="text"
							color={ungrouped.length === 0 ? "secondary" : undefined}
							size="small"
							// className={classes.button}
							endIcon={<GetAppIcon/>}
							startIcon={<ShareIcon/>}
							// disabled={!(ungrouped.length === 0)}
							// onClick={() => setExporting(true)}
							// disableRipple={true}
							// className={classes}
							data-analytics-id="download-trigger-header"
						>
							<LText en="Share / Save" de="Teilen / Speichern"/>
						</Button>
					</Box>
					{canShare && 
						<Box marginLeft={1} display="inline-block" onClick={() => ungrouped.length === 0 ? handleShare() : handleNotReady("share")}>
							<Button
								variant="text"
								color={ungrouped.length === 0 ? "primary" : undefined}
								size="small"
								// className={classes.button}
								startIcon={<ShareIcon/>}
								disabled={!(ungrouped.length === 0)}
								// onClick={() => setExporting(true)}
								// disableRipple={true}
								// className={classes}
							>
								<LText en="Share" de="Teilen"/>
							</Button>
						</Box>
					}
				</>
			)}></HeaderPlain>
			<DialogNotched
				open={notready}
				onClose={() => setNotready(false)}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogContent>
					<Typography variant="h4" gutterBottom>Group all values to enable export</Typography>
					<Typography variant="body1" paragraph>Sort all values into groups. Exporting will become available as soon as no value is without group anymore.</Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setNotready(false)}>
						Close
					</Button>
				</DialogActions>
			</DialogNotched>
			{exporting === null &&
				<VFContainer>
					<ValueGrouper
						groups={groups}
						ungroupedValues={ungrouped}
						doChangeGroup={doChangeGroup}
						doChangeGroupTitle={props.doChangeGroupTitle}
						// showBanner={!bannerDismissed && ungrouped.length === 0}
						showBanner={false}
						// onDismissBanner={() => setBannerDismissed(true)}
						onDismissBanner={() => {}}
					/>
				</VFContainer>
			}
			{exporting !== null &&
				<GroupExporter
					groups={groups}
					ungroupedValues={ungrouped}
					type={exporting}
					onDone={() => setExporting(null)}
				/>
			}
			
			<DialogNotched
				open={showHelp}
				onClose={handleStep2Close}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogContent>
					<Typography variant="h4" gutterBottom><LText en="Step 2: Condense" de="Schritt 2: Verdichten"/></Typography>
					<Typography variant="body1" paragraph><LText en="Create groups of your chosen values. Give each group a descriptive title to summarize your core value." de="Bilde Gruppen aus Deinen gewählten Werten. Gebe jeder Gruppe einen beschreibenden Titel, der den Kern zusammenfasst."/></Typography>
					<Typography variant="body1" paragraph><LText en="Use <b>drag and drop</b> to move your values around. To create a new group, drop a value into the placeholder box at the bottom." de="Halte einen Wert lange gedrückt, um ihn zu verschieben. Lege einen Wert auf das +-Symbol am unteren Rand, um eine neue Gruppe zu anzulegen."/></Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleStep2Close}>
						<LText en="Close" de="Schließen"/>
					</Button>
				</DialogActions>
			</DialogNotched>


			<DialogNotched
				open={doneNoticeState === DoneNoticeState.showing}
				// open={true}
				onClose={() => setDoneNoticeState(DoneNoticeState.shown)}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogContent>
					<Typography variant="h4" gutterBottom><LText en="Well done!" de="Gut gemacht!"/></Typography>
					<Typography variant="body1" paragraph><LText en="Congratulations, you now know your values. Use them to guide your decision making and behavior." de="Du kennst nun Deine Werte. Nutze sie, um Deine Entscheidungen und Verhalten zu verbessern."/></Typography>
					<Box marginBottom={2}>
						<Button
							variant="contained"
							color="primary"
							// size="small"
							// className={classes.button}
							startIcon={<GetAppIcon/>}
							onClick={() => {handleDownload()}}
							data-analytics-id="download-trigger-doneNotice"
						>
							<LText en="Save / Share" de="Speichern / Teilen"/>
						</Button>
					</Box>
					{canShare && 
						<Box marginBottom={2}>
							<Button
								variant="contained"
								color="primary"
								// size="small"
								// className={classes.button}
								startIcon={<ShareIcon/>}
								onClick={() => handleShare()}
							>
								<LText en="Share" de="Teilen"/>
							</Button>
						</Box>
					}
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setDoneNoticeState(DoneNoticeState.shown)}>
						<LText en="Close" de="Schließen"/>
					</Button>
				</DialogActions>
			</DialogNotched>
		</>
	);
}
