import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Slide, useMediaQuery } from '@material-ui/core';
import DragIllustration from './DragIllustrationPathly';

export interface Step1CompleteProps {
	onContinue(): void,
}

export default function Step1Complete(props: Step1CompleteProps) {

	const highEnough = useMediaQuery('(min-height:500px)');

	return (
		<>
			<Box marginLeft="auto" marginRight="auto" width="100%" maxWidth="300px" position="absolute" top="50%" left="50%" style={{transform:"translate(-50%, -50%)"}} textAlign="center">
			{/* <img src={img} style={{maxWidth: "100%", marginBottom:"32px"}}/>  */}
				{highEnough &&
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={700}>
					 <div><DragIllustration/></div>
				</Slide>
				}
				<Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
					<Box>
				<Typography variant="h4" gutterBottom>Well done!</Typography>
				<Typography variant="body1" gutterBottom style={{marginBottom: "16px"}}>Next: Condense your chosen values into core values</Typography>
					</Box>
				</Slide>
				<Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
					<Button onClick={props.onContinue} variant="contained" color="secondary" style={{color: "white", paddingLeft: "50px", paddingRight: "50px"}}>Create groups</Button>
				</Slide>
			</Box>
		</>
	);
}
