import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Chip, Grid } from '@material-ui/core';
// import logger from '../services/logger';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ValueChip from './ValueChip';
import GroupTitle from './GroupTitle';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
	card: {
		width: "100%",
		// marginBottom: theme.spacing(2),
	},
	cardContentRoot: {
		padding: "0 !important",
	},
	title: {
		// fontSize: 56,
		// hyphens: "auto",
		// minHeight: 300,
		textAlign: "center",
		marginBottom: theme.spacing(2),
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
	},
	iconRoot: {
		width: "2em",
		height: "2em",
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	titleChip: {
		marginRight: theme.spacing(1),
		// marginBottom: theme.spacing(1),
		color: "white",
	},
	titlePlaceholder: {
		borderStyle: "dashed",
		// marginBottom: theme.spacing(1),
	},
}));

export interface GroupCardProps {
	id: string,
	titleValue: string | null,
	title: string,
	values: string[],
	onChangeTitle(value: string): void,
}

export default function GroupCard(props: GroupCardProps) {

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Card className={classes.card} elevation={2}>
				<CardContent className={classes.cardContentRoot}>
					<Grid container>
						
						{/* <Grid item xs={3} sm={4}> */}
						{false && 
						<Box display="flex" width="100%">
						<Box flex={1}>
							<Droppable droppableId={props.id + "_leftOfCore"} direction="horizontal">
								{provided => (
									<div
										ref={provided.innerRef}
										style={{
											// backgroundColor:"lightgreen",
											height: "100%",
										}}
										{...provided.droppableProps}
										>
									</div>
								)}
							</Droppable>
							</Box>
						{/* </Grid> */}
						{/* <Grid item xs={6} sm={4} style={{
							textAlign: "center",
							backgroundColor: "lightgrey",
							// height: 0,
							// zIndex: 999,
							// position: "absolute",
							// marginBottom: -55,
							// position: "absolute",
							// width: "calc(100% - 32px)",
						}}> */}
						<Box>
							<Droppable droppableId={props.id + "_coreValue"} direction="horizontal" ignoreContainerClipping={true}>
								{(provided, droppableSnapshot) => (
									<div
										ref={provided.innerRef}
										style={{
											// backgroundColor:droppableSnapshot.isDraggingOver ? "yellow" : "lightyellow",
											marginLeft: "auto",
											marginRight: "auto",
											minHeight: 40,
											paddingTop: 16,
											paddingBottom: 8,
											textAlign: "center",
											// width: !props.titleValue ? 90 : undefined,
											display: "inline-block",
											position: "relative",
											paddingLeft: 32,
											paddingRight: 32,
											// top: 55,
											// zIndex: 9999,
										}}
										{...provided.droppableProps}
										>
										{!props.titleValue &&
											<Draggable draggableId={props.id + "-titlePlaceholder"} index={0}>
												{provided => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														// style={{
														// 	backgroundColor: "lightgreen",
														// 	display: "inline-block",
														// }}
														// style={{...provided.draggableProps.style}}
														style={{display: "inline", ...provided.draggableProps.style}}
													>
														<Chip variant="outlined" className={classes.titlePlaceholder} label="Drop core value here"/>
													</div>
												)}
											</Draggable>
										}
										{props.titleValue &&
											<Draggable draggableId={props.titleValue} index={0}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={{...provided.draggableProps.style}}
														// style={{display: "inline", ...provided.draggableProps.style}}
													>
														{/* {!props.titleValue && <Box textAlign="center"><Chip variant="outlined" className={classes.titlePlaceholder} label="Core Value"/></Box>}
														{props.titleValue && <Box className={classes.title}><Chip label={props.titleValue} color="secondary" avatar={<Avatar>{props.titleValue.substr(0,1)}</Avatar>} className={classes.chip}/></Box>} */}

														{/* <Chip label={props.titleValue} color="secondary" avatar={<Avatar style={{color: "white"}}>{props.titleValue?.substr(0,1)}</Avatar>} className={classes.titleChip}/> */}
														<ValueChip value={props.titleValue || ""} variant={snapshot.isDragging || droppableSnapshot.isDraggingOver ? "dragging" : "title"}/>
													</div>
												)}
											</Draggable>
										}
										{/* {provided.placeholder} */}
									</div>
								)}
							</Droppable>
							</Box>
						{/* </Grid>
						<Grid item xs={3} sm={4}> */}
						<Box flex={1}>
							<Droppable droppableId={props.id + "_rightOfCore"} direction="horizontal">
								{provided => (
									<div
										ref={provided.innerRef}
										style={{
											// backgroundColor:"lightgreen",
											height: "100%",
										}}
										{...provided.droppableProps}
										>
									</div>
								)}
							</Droppable>
							</Box>
						{/* </Grid> */}
					{/* <Box textAlign="center" style={{
							backgroundColor: "lightgrey",
							// height: 0,
							// zIndex: 999,
							// position: "absolute",
							// marginBottom: -55,
							// position: "absolute",
							// width: "calc(100% - 32px)",
						}}
						>

					</Box> */}
					</Box>
					}

					<Grid item xs={12}>
						<Droppable droppableId={props.id} direction="horizontal">
							{provided => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									style={{
										// backgroundColor:"lightyellow",
										minHeight: 40,
										padding: 16,
										paddingTop: 0,
									}}
									>
									<Box textAlign="center" paddingTop={1}>
										<GroupTitle value={props.title} onChange={props.onChangeTitle}/>
									</Box>
									{props.values.map((item, index) => 
										<Draggable key={item} draggableId={item} index={index}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{display: "inline-block", marginTop: 8, ...provided.draggableProps.style}}
													// style={{display: "inline", ...provided.draggableProps.style}}
												>
													{/* <Chip label={item} color="primary" avatar={<Avatar>{item.substr(0,1)}</Avatar>} className={classes.chip}/> */}
													<Box marginRight={1}><ValueChip value={item || ""} variant={snapshot.isDragging ? "dragging" : "default"}/></Box>
												</div>
											)}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
}
