import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import logger from '../services/logger';
import GroupCard from './GroupCard';
import GroupCardPlaceholder from './GroupCardPlaceholder';
import UngroupedCards from './UngroupedCards';
import FocalityBanner from './FocalityBanner';
import { Slide } from '@material-ui/core';
import { logEvent } from '../services/analytics';
import { ValueGroup, ValueGroupIDs } from '../App';


const useStyles = makeStyles(theme => ({
	group: {
		// minHeight: 100,
		// backgroundColor: "lightgrey",
	},
	root: {
		// marginTop: theme.spacing(4),
	},
}));

// export interface ValueGroup {
// 	id: string,
// 	values: string[],
// 	titleValue: string | null,
// }

export interface ValueGrouperProps {
	groups: ValueGroup[],
	ungroupedValues: string[],
	doChangeGroup(value: string, group: string, isCore: boolean, index: number): void,
	doChangeGroupTitle(groupId: string, title: string): void,
	showBanner: boolean,
	onDismissBanner(): void,
}

export default function ValueGrouper(props: ValueGrouperProps) {

	const classes = useStyles();

	// const [unsorted, setUnsorted] = useState();
	// const [group1, setGroup1] = useState(["yo", "dude"]);

	const groupsSorted = props.groups.sort((a, b) => {
		// if (!a.id)
		// 	return -1;
		// if (!b.id)
		// 	return 1;
		if (a.id < b.id)
			return -1;
		else if (a.id === b.id)
			return 0;
		else
			return 1;
	});

	const onDragEnd = (result: DropResult) => {
		logger.debug("onDragEnd", result);
		if (!result.destination)
			return;
		const droppableId = result.destination.droppableId;
		const droppableIndex = result.destination.index;
		if (droppableId === "_new") {
			logger.debug("Creating new group");
			const lastGroup = groupsSorted[groupsSorted.length-1];
			const lastGroupIdInt = lastGroup && lastGroup.id ? parseInt(lastGroup.id) : 0;
			const newGroupId = (lastGroupIdInt + 1).toString().padStart(3, '0');
			logger.debug("New group id:", newGroupId);
			props.doChangeGroup(result.draggableId, newGroupId, false, droppableIndex);
			logEvent("group_create", {
				group_count: props.groups.length+1,
			});
		} else if (droppableId === "_ungrouped") {
			logger.debug("Ungrouping value");
			props.doChangeGroup(result.draggableId, ValueGroupIDs.ungrouped, false, droppableIndex);
		} else {
			logger.debug("Updating group");
			const isCore = droppableId.endsWith("_coreValue");
			let groupId = droppableId;
			if (isCore)
				groupId = groupId.substring(0, groupId.length - 10);
			else if (groupId.endsWith("_leftOfCore"))
				groupId = groupId.substring(0, groupId.length - 11);
			else if (groupId.endsWith("_rightOfCore"))
				groupId = groupId.substring(0, groupId.length - 12);

			// const groupId = !isCore ? droppableId : droppableId.substring(0, droppableId.length - 10);
			logger.debug(`New group: ${groupId} (CoreValue: ${isCore})`);
			if (isCore) {
				// Downgrade old core value if it exists
				const currentTitleValue = props.groups.find(g => g.id === groupId)?.coreValue;
				if (currentTitleValue) {
					logger.debug("Downgrading current core value", currentTitleValue);
					props.doChangeGroup(currentTitleValue, groupId, false, droppableIndex);
				}
			}
			props.doChangeGroup(result.draggableId, groupId, isCore, droppableIndex);
		}
	}


	// const groups = [];
	// props.valueState

	// const _groups = [
	// 	{
	// 		id: "foo1",
	// 		values: ["Originality", "Resourcefulness", "Smartness", "Stimulation", "Ingenuity"],
	// 		titleValue: "Creativity",
	// 	},
	// 	{
	// 		id: "foo2",
	// 		values: ["Love","Positivity", "Friendship", "Fairness"],
	// 		titleValue: null,
	// 	},
	// ];

	// const ungrouped = ["Capability","Communication","Continuous Improvement","Focus on Future", "Making a Difference"];


	return (
		<>
			<Box className={classes.root}>
			<Slide direction="down" in={props.showBanner} mountOnEnter unmountOnExit>
				<Box marginBottom={2}><FocalityBanner onDismiss={props.onDismissBanner}/></Box>
			</Slide>
			<DragDropContext onDragEnd={onDragEnd}>

				<UngroupedCards values={props.ungroupedValues}/>

				{props.groups.sort().map(group => 
					<GroupCard key={group.id} id={group.id} values={group.values} title={group.title} titleValue={group.coreValue} onChangeTitle={(value: string) => props.doChangeGroupTitle(group.id, value)}/>
				)}

				{/* <GroupCard id="group3" values={["Originality", "Resourcefulness", "Smartness", "Stimulation"]} titleValue="Creativity"/>
				<GroupCard id="group1" values={["Love","Positivity", "Friendship", "Fairness"]} titleValue={null}/> */}
				{/* <GroupCard id="group2" values={["Merit"]} titleValue={null}/> */}
				{/* <GroupCard id="group4" values={["Peace"]} titleValue={null}/>
				<GroupCard id="group5" values={["Openness to Experience"]} titleValue={null}/> */}
				<GroupCardPlaceholder id="group6"/>


			</DragDropContext>

			
			</Box>
		</>
	);
}
