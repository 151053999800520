import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import logger from '../services/logger';
import { Droppable } from 'react-beautiful-dnd';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
	box: {
		padding: theme.spacing(2),
		color: theme.palette.grey[400],
		borderRadius: 4,
		borderWidth: 1,
		borderStyle: "dashed",
		borderColor: theme.palette.grey[400],
		textAlign: "center",
		margin: theme.spacing(2),
		// display: "absolute",
	},
}));

export interface GroupCardPlaceholderProps {
	id: string,
}

export default function GroupCardPlaceholder(props: GroupCardPlaceholderProps) {

	const classes = useStyles();

	return (
				<Droppable droppableId="_new" direction="horizontal">
					{provided => (
						<div
							ref={provided.innerRef}
							className={classes.box}
							style={{
								// backgroundColor:"lightblue",
							}}
							{...provided.droppableProps}
							>
							<AddCircleIcon fontSize="large"/>
						</div>
					)}
				</Droppable>
	);
}
